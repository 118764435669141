$minimum-height: 250px;
$link-typography: (
  "mobile": (
    "font-family": bold,
    "font-size": 12,
    "line-height": 1.2,
    "letter-spacing": -0.15px,
  ),
  "laptop": (
    "font-size": 15,
  ),
);

$heading-typography: (
  "mobile": (
    "font-family": bold,
    "font-size": 26,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 34,
  ),
);

$text-typography: (
  "mobile": (
    "font-family": regular,
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

.category-promoted-service {
  position: relative;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  min-height: $minimum-height;
  margin: 0;
  overflow: hidden;

  @include MQ-up("tablet") {
    grid-column-end: 4;
  }

  .category-list--no-aside & {
    @include MQ-up("tablet") {
      grid-column-end: 3;
    }

    @include MQ-up("laptop") {
      grid-column-end: 4;
    }

    @include MQ-up("desktop") {
      grid-column-end: 5;
    }
  }

  .product-list--multiline & {
    grid-column-end: 2;

    @include MQ-up("laptop") {
      grid-column-end: 4;
    }
  }

  .product-list--4-col-max & {
    grid-column-end: 3;

    @include MQ-up("tablet") {
      grid-column-end: 4;
    }

    @include MQ-up("laptop") {
      grid-column-end: 5;
    }
  }

  &__link {
    display: flex;
    flex-direction: column;
    height: 100%;

    &:hover .category-promoted-service__show-more::after {
      margin-left: var(--space-mini);
    }

    @include MQ-up("laptop") {
      flex-direction: row-reverse;
    }
  }

  figure {
    width: 100%;
    height: auto;

    @include MQ-up("laptop") {
      width: 50%;
      height: 100%;
    }

    img {
      display: block;
      width: 100%;
      height: auto;

      @include MQ-up("laptop") {
        width: auto;
        height: 100%;
      }
    }
  }

  &__details {
    position: relative;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: var(--space-small-regular);
    color: $color-xxl-web-black;
    text-align: left;
    background-color: $color-xxl-white;
    border-right: 1px solid $color-xxl-light-gray;
    border-bottom: 1px solid $color-xxl-light-gray;
    border-left: 1px solid $color-xxl-light-gray;

    @include MQ-up("laptop") {
      padding: var(--space-large) var(--space-small-huge) var(--space-large)
        var(--space-large);
      border-top: 1px solid $color-xxl-light-gray;
      border-right: none;
    }

    @include MQ-up("desktop") {
      padding: var(--space-large) var(--space-giant) var(--space-large)
        var(--space-large);
    }

    .category-promoted-service--grey & {
      background-color: $color-xxl-light-gray;
    }

    .category-promoted-service--black & {
      color: $color-xxl-white;
      background-color: $color-xxl-web-black;
      border-right: 1px solid $color-xxl-web-black;
      border-bottom: 1px solid $color-xxl-web-black;
      border-left: 1px solid $color-xxl-web-black;

      @include MQ-up("laptop") {
        border-top: 1px solid $color-xxl-web-black;
        border-right: none;
      }
    }
  }

  &__header {
    @include Typography($heading-typography);
    margin: 0 0 var(--space-mini);
    color: currentColor;
  }

  &__text {
    @include Typography($text-typography);
    margin: 0;
    color: currentColor;
  }

  &__show-more {
    @include Typography($link-typography);
    position: relative;
    display: inline-flex;
    align-items: center;
    margin: var(--space-big) 0 0;
    color: currentColor;
    text-decoration: none;

    @include MQ-up("laptop") {
      margin: auto 0 0;
    }

    &::after {
      display: inline-block;
      width: 4px;
      height: 4px;
      margin-top: var(--space-hair);
      margin-left: var(--space-micro);
      border-top: 1px solid currentColor;
      border-right: 1px solid currentColor;
      transform: rotate(45deg);
      transition: 0.2s ease;
      content: "";

      @include MQ-up("laptop") {
        width: 6px;
        height: 6px;
        margin-top: var(--space-line);
      }
    }
  }
}

.category-promoted-service__banner {
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  margin: 0;

  @include MQ-up("tablet") {
    grid-column-end: 4;
  }

  @include MQ-up("laptop") {
    flex-direction: row-reverse;
  }

  .category-list--no-aside & {
    @include MQ-up("tablet") {
      grid-column-end: 3;
    }

    @include MQ-up("laptop") {
      grid-column-end: 4;
    }

    @include MQ-up("desktop") {
      grid-column-end: 5;
    }
  }

  .product-list--multiline & {
    grid-column-end: 2;

    @include MQ-up("laptop") {
      grid-column-end: 4;
    }
  }

  .product-list--4-col-max & {
    grid-column-end: 3;

    @include MQ-up("tablet") {
      grid-column-end: 4;
    }

    @include MQ-up("laptop") {
      grid-column-end: 5;
    }
  }

  figure {
    img {
      width: 100%;
    }

    @include max-screen("tablet") {
      width: 100%;
    }
  }

  @include max-screen("mobile") {
    width: 100%;
    margin-left: 0;
  }
}
