@import "../theme/hero-banner.theme";

$button-min-width: 198px;

.hero-component {
  overflow: hidden;
  text-align: center;

  &__heading-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;

    padding-right: $hero-component-right-padding-sm;
    color: var(--font-color-primary);

    @include MQ-up("tablet") {
      padding-right: $hero-component-right-padding;
    }

    &--text-top {
      justify-content: flex-start;
    }

    &--text-bottom {
      justify-content: flex-end;
    }
  }

  &__heading {
    @include Typography($hero-heading);
    margin: 0 0 var(--space-micro);

    @include MQ-up("tablet") {
      margin: 0 0 var(--space-small-regular);
    }
  }

  &__sub-heading {
    @include Typography($hero-sub-heading);
    margin: 0 0 var(--space-micro);

    @include MQ-up("tablet") {
      margin: 0 0 var(--space-small-regular);
    }
  }

  &__xxl-label {
    @include Typography($hero-label);
    display: flex;
    align-items: center;
    margin-bottom: var(--space-line);
    @include MQ-up("tablet") {
      margin-bottom: var(--space-mini);
    }

    img {
      margin-right: var(--space-small-regular);
    }
  }

  .hero-component__link {
    display: block;
  }

  .hero-component__hero-image {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 284px;
    overflow: hidden;

    &--image-overlay {
      figure {
        @include gradientImageOverlay();
      }
    }

    figure,
    .banner-image {
      // Overriding Slick values
      display: inline !important;
      width: 100vw !important;
      max-width: $maxContainerWidth;
      height: 100% !important;
      object-fit: cover;
    }

    &__height-560 {
      height: 380px;
      @include MQ-up("tablet") {
        height: 560px;
      }
    }
    &__height-400 {
      height: 200px;
      @include MQ-up("tablet") {
        height: 400px;
      }
    }
  }

  .hero-component__info {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    justify-content: flex-start;
    box-sizing: border-box;
    padding: 24px 12px 54px;
    text-align: left;

    @include MQ-up("tablet") {
      padding: 54px 24px 80px;
    }

    &.black {
      * {
        color: var(--font-color-primary);
      }
      .hero-component__button {
        color: var(--font-color-primary-invert);
        @include hover-background(var(--font-color-primary), $color-xxl-green);
        &:hover {
          color: var(--font-color-primary);
        }
      }
    }
    &:not(.black) {
      * {
        color: var(--font-color-primary-invert);
      }
      .hero-component__button {
        color: var(--font-color-primary);
        @include hover-background(
          var(--font-color-primary-invert),
          $color-xxl-green
        );
      }
    }
  }
  &__button-wrap {
    margin-top: var(--space-small-regular);
    margin-right: var(--space-small-regular);
  }
  &__buttons-wrap {
    display: flex;
    margin-bottom: -30px;

    @include MQ-up("tablet") {
      margin-bottom: -37px;
    }
  }

  @include MQ-down("tablet") {
    .hero-component__button-wrap:nth-child(n + 3) {
      display: none;
    }
  }

  &--one-slide {
    .hero-component__info {
      display: flex;
      padding-bottom: 24px;

      @include MQ-up("tablet") {
        padding-bottom: 44px;
      }
    }
  }

  &:not(.hero-component__teamsales) .hero-component__link {
    &:hover .hero-component__button {
      color: $color-xxl-web-black;
      background-color: $color-xxl-green;
    }
  }

  .hero-component__wrap {
    position: relative;

    .loader {
      position: absolute;
      top: 50vh;
      left: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }

  &.hero-component__teamsales {
    .hero-component__button {
      margin-right: 14px;
      &:last-child {
        margin: 0;
      }
    }
  }

  .slick-dots {
    bottom: 24px;
    left: 12px;

    @include MQ-up("tablet") {
      bottom: 44px;
      left: 50px;
    }
  }

  .slick-prev {
    left: 7px;
  }

  .slick-arrow {
    bottom: 32px;
    height: 20px;
  }

  // Prevent other slides to be visible until Slick is initialized
  &.js-one-item-carousel:not(.slick-initialized) {
    > *:not(:first-child) {
      display: none;
    }
  }
  &__bullet-list {
    padding: 0;
    list-style-type: none;

    > li {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: var(--space-small-large);
      @include Typography($hero-bullet);

      @include MQ-up("tablet") {
        margin-bottom: 46px;
      }
    }
  }
  $bullet-icon-size: 28px;
  &__bullet-icon {
    display: flex;
    width: $bullet-icon-size;
    margin-right: var(--space-regular);

    svg {
      width: 100%;
      height: auto;
    }
  }
}

.hero-component__countdowntimer {
  min-height: 56px;

  @include MQ-up("tablet") {
    min-height: 102px;
  }
}
