@import "../theme/product.theme";
@import "./z-indexes";

$imageSizeMobile: 379.5px;
$ribbonOffsetMobile: 32px;
$ribbonOffsetDesktop: var(--space-small-regular);
$whiteGray: var(--color-xxl-light-gray);

.product {
  $leftMargin: 12px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: calc(100% - $leftMargin);
  margin: 0 0 41px $leftMargin;

  @include MQ-up("tablet") {
    flex-wrap: wrap;
    max-width: calc(100% - $containerOffset);
    margin-top: 0;
    margin-right: auto;
    margin-left: auto;
  }

  @include MQ-up("laptop") {
    flex-wrap: nowrap;
  }

  @media only screen and (min-width: map-get($new-breakpoints, "big-desktop")) {
    max-width: $maxContainerWidth;
  }

  .js-product__large-image {
    @include max-screen("mobile") {
      width: 100%;
      height: auto;
      margin: 0 auto;
    }
  }

  &__price-wrap {
    display: flex;
    margin-bottom: 22px;

    a[class^="prisjakt_cheapest"] {
      margin: -10px 12px;

      @include MQ-up("laptop") {
        margin: -6px 12px;
      }
    }
  }

  &__price-info-disc {
    @include Typography($product-price-label);
    margin: 0 0 14px 14px;
    color: var(--color-xxl-web-gray);

    @include max-screen("mobile") {
      margin-left: 28px;
    }
  }

  &__name {
    margin: 8px 0 21px;
    padding-right: 16px;
    font-size: px-to-rem(15);

    @include MQ-up("laptop") {
      margin-top: 0;
      font-size: px-to-rem(20);
    }
  }

  &__brand-image {
    height: 24px;

    @include MQ-up("tablet") {
      height: 32px;
    }
  }

  &__name-wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    order: 0;
  }

  &__rating {
    &--react {
      width: 167px; //CLS prevention
    }

    @include MQ-up("laptop") {
      margin-bottom: auto;
    }
  }

  .product-card__previous-price-wrapper,
  .product-card__inverted-price-wrapper,
  .product-card__current-price-wrapper {
    font-size: px-to-rem(12);

    .price__label {
      margin-top: 10px;

      @include MQ-up("laptop") {
        margin-top: 6px;
      }
    }
  }

  .product-card__current-price,
  .product-card__inverted-price,
  .product-card__previous-price {
    font-size: px-to-rem(36);
  }
}

.product__images-wrap {
  width: 100%;
  margin: 0 auto;

  @include MQ-up("laptop") {
    max-width: 500px;
  }

  @include MQ-up("desktop") {
    width: 100%;
    max-width: 600px;
    margin-left: $containerOffset;
  }

  @include MQ-up("big-desktop") {
    max-width: 700px;
  }

  picture {
    display: block;
  }

  .slick-dots {
    bottom: 21px;
    width: calc(100% - $containerOffset);

    li {
      height: auto;
      padding: var(--space-small-regular) var(--space-hair);
    }
  }

  .slick-slider:not(.js-one-item-carousel) .slick-dots {
    margin: 0 auto;
  }
}

.product__arrows-wrapper {
  position: relative;

  .slick-arrow {
    top: 50%;
    color: $color-xxl-gray;
    background-color: transparent;
  }

  .slick-prev {
    left: -30px;
  }

  .slick-next {
    right: -30px;
  }

  @include MQ-up("desktop") {
    .slick-prev {
      left: -34px;
    }

    .slick-next {
      right: -34px;
    }
  }

  @include MQ-up("big-desktop") {
    .slick-prev {
      left: -37px;
    }

    .slick-next {
      right: -37px;
    }
  }
}

.product__images {
  $imageWidth: 80vw;
  min-height: 50px; // for the edga case if there is no image.
  overflow-y: hidden;
  visibility: hidden;
  cursor: pointer;

  &.slick-initialized {
    visibility: visible;
  }

  &:not(.slick-initialized) {
    figure {
      width: $imageWidth;
      overflow: hidden;

      img {
        width: $imageWidth;
      }

      &:nth-child(1n + 2) {
        display: none;
      }
    }
  }

  &--multiple {
    height: calc(100% + 20px);

    figure {
      width: $imageWidth;
    }
  }

  &--single {
    margin-bottom: 10px;

    .slick-slide {
      width: calc(100vw - $containerOffset);
    }
  }

  .slick-slide {
    margin-right: 4px;

    @include MQ-up("laptop") {
      margin-right: 0;
    }
  }

  .slick-track {
    overflow: hidden;
  }

  .slick-dots {
    width: 100%;
    max-width: calc(100vw - $containerOffset);
    margin: 0 !important;

    li {
      padding-top: 8px;
    }
  }
}

.slick-slide img {
  width: 100%;
  height: auto;

  @include max-screen("mobile") {
    width: 100%;
    height: auto;
  }
}

.product__images .slick-list {
  overflow: hidden;
}

.product__images-label {
  position: absolute;
  bottom: $ribbonOffsetMobile;
  left: 0;
  z-index: 1;
  @include Typography($product-image-label);

  @include MQ-up("tablet") {
    bottom: $ribbonOffsetDesktop;
  }
}

.product__mini-images {
  position: relative;
  top: 0;
  width: calc(100% - 64px);
  max-width: 65%;
  margin: var(--space-small-regular) auto 0;
  overflow: hidden;
  cursor: pointer;

  &:not(.slick-initialized) {
    visibility: hidden;
  }

  @include MQ-up("tablet") {
    display: flex;
    justify-content: center;
    max-width: 85%;
  }

  @include MQ-up("laptop") {
    display: block;
    max-width: 545px;
  }

  @include MQ-up("desktop") {
    max-width: 722px;
  }

  @include max-screen("mobile") {
    &.slick-slider {
      display: none;
      /* need to override slick-slider for this module */
    }
  }

  .slick-current > picture {
    box-sizing: border-box;
    border: 1px solid $color-xxl-gray !important;
  }

  .slick-track {
    margin: 0;
  }

  .slick-list {
    margin-right: -8px;
  }

  picture {
    height: 100%;
    max-height: 108px;
    margin-right: 8px;
    overflow: hidden;

    @include MQ-up("desktop") {
      max-height: 138px;
    }

    & img {
      width: 100%;
      height: 100%;
    }
  }
}

.product__mini-images-arrows {
  $arrowOffset: 1px;
  $arrowOffsetDesktop: -2px;
  $arrowOffsetBigDesktop: -5px;
  position: relative;
  z-index: 2;
  display: none;
  width: calc(65% + 64px);
  margin: 0 auto;
  pointer-events: none;

  @include MQ-up("tablet") {
    display: block;
  }

  @include MQ-up("laptop") {
    left: -32px;
    width: calc(100% + 64px);
  }

  @include MQ-up("desktop") {
    left: -32px;
    display: block;
    width: calc(100% + 64px);
  }

  > * {
    pointer-events: all;
  }

  .slick-arrow {
    top: 50%;
    color: $color-xxl-gray;
    background-color: transparent;
  }

  .slick-prev {
    left: 0;
    @include MQ-up("laptop") {
      left: $arrowOffset;
    }

    @include MQ-up("desktop") {
      left: $arrowOffsetDesktop;
    }

    @include MQ-up("big-desktop") {
      left: $arrowOffsetBigDesktop;
    }
  }

  .slick-next {
    right: 0;
    @include MQ-up("laptop") {
      right: $arrowOffset;
    }

    @include MQ-up("desktop") {
      right: $arrowOffsetDesktop;
    }

    @include MQ-up("big-desktop") {
      right: $arrowOffsetBigDesktop;
    }
  }
}

.product__information-wrapper {
  align-self: baseline;
  box-sizing: border-box;
  width: 100%;
  min-height: 551px;
  margin: 0 auto 0;
  padding-right: 12px;

  @include MQ-up("tablet") {
    margin: var(--space-small-regular) auto 0;
    padding-right: 0;
  }

  @include MQ-up("laptop") {
    flex-grow: 1;
    width: 503px;
    margin: 0 0 0 38px;
  }

  .product__mini-images,
  .style-selection {
    margin: 7px 0 15px;

    .slick-slide {
      width: 60px !important; // slick sets width as inline style

      @include MQ-up("tablet") {
        width: 80px !important;
      }

      @include MQ-up("laptop") {
        width: 138px !important;
      }
    }
  }
}

.product__border-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 0;
  background-color: var(--color-xxl-white);

  @include MQ-up("tablet") {
    padding: var(--space-regular);
    border: 1px solid $color-xxl-medium-gray;

    &.product--has-splash,
    &.product--has-label {
      border-top: 0;
    }

    & .product__information-footer button:last-child {
      margin-bottom: 0;
    }
  }

  @include MQ-up("desktop") {
    .product__price {
      margin-top: 6px;
    }
    .product__arrows-wrapper {
      margin-top: 17px;
    }
  }
}

.product__delivery-wrapper {
  margin-top: 12px;
  padding: 0;
  .product__additional-info {
    .product__additional-info-stock {
      box-sizing: border-box;
      min-height: 43px; // Prevent CLS
      margin-bottom: unset;
      padding: var(--space-regular);

      @include MQ-up("desktop") {
        padding: var(--space-regular);
      }
    }
  }
}

.product__information-footer {
  position: relative;

  @include max-screen("tablet") {
    padding-bottom: 0;
  }
}

.product__brand-link {
  display: block;
  margin: 0;

  @include max-screen("mobile") {
    margin-top: 0;
  }

  figure {
    display: flex;
    align-items: center;
    min-height: 32px;
  }
}

.product__brand-name {
  @include Typography($product-brand-name);
  display: flex;
  align-items: center;
  margin: 0;

  svg,
  [data-inline-svg] {
    width: 21px;
    height: 21px;
    vertical-align: middle;
    fill: var(--color-xxl-black);
    @include max-screen("mobile") {
      margin-left: 0;
    }
  }
}

.product__information-wrapper--product-page {
  .product__price {
    display: flex;
    flex-direction: row;
  }

  .product__price-info-disc {
    display: flex;
    flex-direction: column-reverse;
    max-width: 80%;
    margin-bottom: auto;
    margin-left: 0;
  }

  .product__previous-price-label,
  .product__price-info,
  .product__price-info-disc {
    font-size: px-to-rem(15);
  }
}

.product__price {
  margin: 0 0 7px;

  .product__inverted-price,
  .product__price-main,
  .product__previous-price {
    @include Typography($product-price-strong);
    margin: 0;
    color: var(--color-xxl-web-black);

    @include discountedPrice;
  }

  .product__previous-price-and-disclaimer-wrap {
    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: auto;
  }

  .product__previous-price-wrap {
    display: inline-flex;
    flex-direction: column;
    margin: 0;
    color: $color-xxl-web-gray;
  }

  .product__previous-price-label {
    @include Typography($product-price-label);
    display: inline-block;
    margin: 0;
    color: var(--color-xxl-web-gray);
  }
}

.product__price-info {
  margin: 0;
  color: var(--color-xxl-web-gray);
  @include Typography($product-price-label);

  a {
    color: var(--color-xxl-black);
    text-decoration: underline;
  }
}

.product__price-list-wrapper {
  display: flex;
  align-items: flex-start;
}

.product__inverted-price {
  @include Typography($header-styles);
  @include Typography($h4-styles);
  @include Typography($product-inverted-price);

  @include MQ-up("tablet") {
    @include Typography($h3-styles);
  }
  display: block;
  color: var(--color-xxl-red);

  &--promo {
    color: var(--color-xxl-web-gray);
    text-decoration: line-through;
  }
}

div.product__price-info-disc {
  margin: 7px;
  text-align: center;
}

.product__buy-wrap {
  display: grid;
  grid-row-gap: var(--space-mini);
  grid-column-gap: var(--space-mini);
  grid-template-rows: repeat(2, auto);
  grid-template-columns: 92px 1fr 2fr 1fr;
  align-content: center;
  justify-content: center;

  &.product__klarna-click-and-collect--active {
    grid-template-columns: repeat(4, 2fr);
  }

  @include max-screen("mobile") {
    grid-row-gap: var(--space-regular);
    grid-template-columns: 64px 1fr;
  }

  .product__button-disable {
    @include button-disabled;
  }

  .product__messages-wrapper {
    display: flex;
    flex-direction: column;
    grid-row-start: 3;
    grid-column-start: 1;
    grid-column-end: 3;
    margin-top: 0;

    @include respond-to("mobile") {
      grid-column-end: 5;
    }
  }

  .product__size-guide-wrap {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-column-end: 4;
    margin-top: 6px;

    & a {
      @include Typography($product-size-guide);
      display: flex;
      color: $color-xxl-web-gray;
    }
  }

  .product__size-guide-svg {
    position: relative;
    top: 1px;
    width: 18px;
    height: 18px;
    margin-right: var(--space-micro);
  }
}

.product__select-size {
  z-index: map-get($pdp-z-indexes, "productSizeSelector");
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 5;
}

.product__quantity-box-input {
  grid-row-start: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  font-size: 1rem;
  text-align: center;
  border: 1px solid $whiteGray;
  border-color: $whiteGray;

  & ~ .product__select-size {
    grid-column-start: 2;
  }
}

.product__klarna-container {
  position: relative;
  grid-row-start: 4;
  grid-column-start: 1;
  grid-column-end: 5;
  width: 100%;
}

.product__add-to-cart {
  position: relative;
  grid-row-start: 4;
  width: inherit;
  @include MQ("tablet") {
    margin-bottom: 7px;
  }
}

.product__add-to-cart.site__button-checkmark {
  background-color: $color-xxl-green;
}

.product__size-guide-wrap {
  display: flex;
  align-items: center;
  margin-top: 6px;

  a {
    display: flex;
    text-decoration: underline;
  }
}

.product__size-guide-icon {
  margin-right: 7px;
}

.product__klarna-placement-widget {
  display: flex;
  grid-row-start: 6;
  grid-column-start: 1;
  grid-column-end: 5;
  justify-content: flex-start;
  min-height: 37px;
  margin-left: 7px;

  .product__buy-wrap & {
    margin-left: -8px;
  }
}

.product__promotion-message {
  position: relative;
  display: none;
  align-self: baseline;
  box-sizing: border-box;
  width: 100%;
  min-height: 80px;
  margin: var(--space-regular) 0 0;
  padding: var(--space-regular);
  color: $color-xxl-white;
  background-color: $color-xxl-web-black;

  &.product__promotion-message-campaign {
    display: block;

    .product__promotion-message-campaign-header {
      margin-bottom: 4px;
      @include Typography($product-promotion-message-header);
    }

    .product__promotion-message-campaign-text {
      @include Typography($product-promotion-message-text);
      margin-bottom: var(--space-large);

      @include MQ-up("laptop") {
        margin-bottom: var(--space-big);
      }
    }

    .product__promotion-message-campaign-link a {
      @include Typography($product-promotion-message-link);
      position: relative;
      display: inline-block;
      padding-right: var(--space-mini);

      &::after {
        position: absolute;
        top: 50%;
        right: 0;
        display: block;
        width: 6px;
        height: 6px;
        border-top: 1px solid $color-xxl-white;
        border-right: 1px solid $color-xxl-white;
        transform: rotate(45deg) translateY(-50%);
        transition: right 0.2s ease;
        content: "";
      }

      &:hover::after {
        right: -4px;
      }
    }
  }

  a {
    color: $color-xxl-white;
  }

  a:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.product-video-thumbnail {
  position: relative;

  picture {
    height: 100%;
  }
}

.product__video-play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35px;
  height: 35px;
  background-color: var(--color-xxl-white);
  border: 4px solid var(--color-xxl-white);
  border-radius: 54px;
  transform: translate(-50%, -50%);
  cursor: pointer;

  @include max-screen("mobile") {
    top: 15px;
    left: 23px;
    width: var(--space-small-large);
    height: var(--space-small-large);
  }

  &:after {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 12px solid var(--color-xxl-black);
    transform: translate(-50%, -50%);
    content: "";

    @include max-screen("mobile") {
      top: 1px;
      left: 3px;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid var(--color-xxl-black);
    }
  }
}

.product-video-slider {
  position: relative;
}

.product-video-slider__item-video {
  display: block;
  width: 100%;
  height: 500px;
  margin: 0 auto;

  @include max-screen("mobile") {
    height: 374px;
  }

  &:focus {
    outline: none;
  }
}

.active .product__button-text {
  display: none;
}

.product__error-message,
.product__info-message {
  display: none;
  align-items: center;
  width: 100%;
  margin-top: 7px;
  margin-bottom: 7px;

  svg,
  [data-inline-svg] {
    margin-top: 2px;
    margin-right: 7px;
    vertical-align: top;
  }

  span {
    display: none;
    width: calc(100% - 30px);
    font-size: 0.8rem;

    &.product__error-message-text--visible {
      display: inline-block;
    }

    &.product__info-message-text--visible {
      display: inline-block;
    }
  }
}

.product__error-message {
  color: var(--color-xxl-red);
}

.product__error-message--visible,
.product__info-message--visible {
  display: flex;
}

.product__no-stock .product__add-to-cart {
  background: var(--color-xxl-gray);
  pointer-events: none;

  svg,
  [data-inline-svg] {
    display: none;
  }
}

.product__klarna-click-and-collect--active {
  .product__klarna-container {
    grid-column-end: 3;

    @include max-screen("mobile") {
      grid-column-end: 5;
    }
  }

  .click-and-collect {
    grid-row-start: 4;
    grid-column-start: 3;
    grid-column-end: 5;
    margin-right: 1px;
    margin-bottom: var(--space-small-regular);

    @include max-screen("mobile") {
      grid-row-start: 5;
      grid-column-start: 1;
      margin-right: 0;
    }

    iframe {
      width: 100%;
    }
  }

  .product__klarna-placement-widget {
    margin-top: 0;
  }

  .product__usp-wrap {
    margin-top: 7px;
  }
}

.product__price-and-review-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: var(--space-regular);

  .product__rating {
    min-height: 22px;
    margin-bottom: 7px;
  }
}

.product__name-price-wrap {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;

  @include MQ-up("tablet") {
    flex-direction: row;
    gap: var(--space-large);
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .product__name {
    @include Typography($product-name);
    margin-bottom: var(--space-regular);
  }

  .product__brand-wrap {
    .product__brand-info {
      width: 100%;
      margin: 0;

      @include MQ-up("tablet") {
        margin: 0;
      }

      .product__brand-name {
        text-decoration: underline;
      }
    }

    .js-product__countdown-and-ribbon {
      position: absolute;
      top: -1px;
      right: -1px;
      margin-bottom: 5px;

      & + .product__brand-info {
        min-height: 40px;

        @include MQ-up("laptop") {
          min-height: auto;
        }
      }
    }
  }
}

.product__additional-info {
  display: flex;
  flex-direction: column;

  .product__additional-info-stock {
    min-height: 43px; // Prevent CLS
    margin-bottom: var(--space-small-large);
  }

  h2 {
    text-transform: none;
  }

  .product__additional-info-message {
    box-sizing: border-box;
    width: 100%;
    margin-top: var(--space-regular);
    padding: var(--space-regular);
    background-color: $color-xxl-web-black;

    .info-message__message,
    .info-message__button {
      color: $color-xxl-white;
      @include Typography($product-info-message);
      &:hover {
        text-decoration: none;
      }
    }

    .info-message__message {
      display: inline;
      margin: 0 0 7px;
      padding-right: 3px;
      font-family: "open sans bold", sans-serif;
    }

    .info-message__button {
      display: inline;
      padding: 0;
      text-decoration: underline;
      background: transparent;
      border: 0;
      outline: none;
      cursor: pointer;
    }

    &--campaign {
      background-color: $color-xxl-orange;

      .info-message__message,
      .info-message__button {
        color: $color-xxl-web-black;
      }
    }

    &--reward {
      background-color: $color-xxl-dark-purple;
    }
  }

  @include max-screen("mobile") {
    flex-direction: column;
    margin-bottom: 0;
    padding-bottom: 0;

    & > div {
      width: 100%;
    }

    .product__additional-info-stock {
      margin-bottom: 0;
    }
  }
}

.product__usp-wrap {
  grid-row-start: 7;
  grid-column-start: 1;
  grid-column-end: 5;
}

.product__usp-list {
  margin: var(--space-small-large) 0;
  padding: 0;
  list-style: none;

  @include MQ-up("tablet") {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 4px;
  }

  .product__usp-list-item {
    display: flex;
    align-items: center;
    margin-bottom: var(--space-regular);

    @include MQ-up("tablet") {
      width: 50%;
    }

    .product__usp-text {
      font-size: px-to-rem(12);

      &--main {
        margin-left: var(--space-mini);
        font-family: var(--font-family-bold);
      }

      &--secondary {
        margin-left: var(--space-micro);
      }
    }
  }

  .xxl-checkmark-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background-color: var(--color-xxl-green);
    border-radius: 50%;

    svg {
      position: relative;
      top: 1px;
      width: 13px;
      height: 11px;

      path {
        stroke: var(--color-xxl-black);
        stroke-width: 2;
      }
    }
  }
}

.product__campaign-icon-wrapper {
  position: relative;
  top: var(--space-bigger-big);
  left: 10px;
  z-index: 1;

  @include MQ-up("tablet") {
    top: var(--space-large);
    left: var(--space-large);
  }

  @include MQ-up("desktop") {
    left: var(--space-small-huge);
  }

  .product__campaign-icon {
    position: absolute;
    width: 51px;

    @include MQ-up("desktop") {
      width: 97px;
    }
  }
}

.product__splash-and-ribbon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  .product-card__splash-info {
    margin-left: 22px;
    font-size: px-to-rem(12);
    font-family: var(--font-family-medium);

    @include MQ-up("laptop") {
      font-size: px-to-rem(15);
    }

    a {
      font-family: var(--font-family-medium);
    }
  }

  .product__countdown-and-ribbon {
    display: flex;
    margin-left: auto;
  }

  &--mobile {
    position: absolute;
    left: 0;
    z-index: 1;
    width: 100vw;

    @include MQ-up("tablet") {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include MQ-up("tablet") {
      display: flex;
      margin: 0;
      padding-left: var(--space-regular);
      border: var(--space-hair) solid var(--color-xxl-medium-gray);
      border-bottom: none;

      .product-card__splash-info {
        margin: 0;
      }
    }
  }
}

.product__overlay {
  position: fixed;
  inset: 0;
  z-index: 101;
  display: none;
  width: 100vw;
  height: var(--xxl-vh);
  background-color: rgba(22, 22, 22, 0.6);
}

.breadcrumb-brand-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include MQ-up("laptop") {
    max-width: calc(100% - $containerOffset);
    margin: 0 auto;
    padding: 0;

    .product__brand-name,
    .product__brand-link {
      display: none;
    }
  }

  @include MQ-up("big-desktop") {
    max-width: $maxContainerWidth;
  }

  .container {
    margin-left: 0;
  }
}

.product__store-availability {
  grid-row-start: 7;
  grid-column-start: 1;
  grid-column-end: 5;
  min-height: 45px;
  margin-bottom: 0;
  border-bottom: solid 1px var(--color-xxl-medium-gray);

  @include MQ-up("tablet") {
    margin-bottom: var(--space-small-large);
  }

  &--one-size {
    min-height: 122px;

    @include MQ-up("tablet") {
      min-height: 62px;
    }
  }

  &--after-usps {
    grid-row-start: 8;
  }
}

.sticky-add-to-cart {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: map-get($pdp-z-indexes, "stickyAddToCart");
  display: flex;

  @include MQ-up("tablet") {
    display: none;
  }

  svg {
    width: 14px;
  }

  &--hidden {
    transform: translateY(100%);
    transition: 0.2s ease;
  }

  &__button {
    display: flex;
    align-items: center;

    &-text {
      margin-left: 8px;
    }

    &-price {
      margin-left: 12px;
      font-size: px-to-rem(20);
    }
  }
}
