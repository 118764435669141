@use "sass:math";

@import "./font-sizes";
@import "./variables";
@import "./mixins";

// This is only example of variables list, which can be used in Typography mixin. It's prepared for mobile-first approach.
$global-typography: (
  "mobile": (
    "font-family": regular,
    "font-style": normal,
    "font-size": 16px,
    // available units: px, rem, other unit or none is treated as px
    //  "font-weight": 400 - it must not be used, when font-family is using 'regular', 'medium' or 'bold' keywords
    "line-height": 1.2,
    // available units: px or none. Pixels are changed to number (16px => 1)
    "letter-spacing": 0.2,
    "text-transform": uppercase,
    "text-align": left,
    "text-decoration": none,
    "white-space": normal,
  ),
  "tablet": (
    "font-size": 1.5rem,
    // here we can add only styles we'd like to overwrite on tablet+ breakpoint
  ),
  // we can add multiple objects - key must be equal to one of $new-breakpoints in variables.scss file
  "desktop":
    (
      "font-size": 20,
    ),
);

// helpers
@mixin lineHeight($value) {
  @if (unit($value) == "px") {
    $unified: math.div(strip-unit($value), 16);
    line-height: $unified;
  } @else {
    line-height: $value;
  }
}

@mixin fontSize($value) {
  @if (unit($value) == "rem") {
    font-size: $value;
  } @else {
    font-size: px-to-rem($value);
  }
}

@mixin fontFamily($string) {
  $varSubstr: "--";
  font-weight: normal;
  @if str-index($string, $varSubstr) == 1 {
    font-family: $string;
  } @else if str-index($string, regular) == 1 {
    font-family: var(--font-family-regular);
  } @else if str-index($string, medium) == 1 {
    font-family: var(--font-family-medium);
  } @else if str-index($string, bold) == 1 {
    font-family: var(--font-family-bold);
  } @else {
    font-family: $string;
  }
}

// Mixin for styles loop in every breakpoint
@mixin breakpointStyles($array) {
  @each $name, $value in $array {
    @if $name == "font-size" {
      @include fontSize($value);
    } @else if $name == "font-family" {
      @include fontFamily($value);
    } @else if $name == "line-height" {
      @include lineHeight($value);
    } @else if $name == "letter-spacing" {
      #{$name}: px-to-rem($value);
    } @else {
      #{$name}: $value;
    }
  }
}

/* Mixin which should be used for typography. As an argument it needs array with "style": value pairs, eg:
  "font-size": 12(px, rem), - this is calculated as pixels to rem or (if rem) just copied;
  "font-family": regular, - this uses css variables for font families - regular, medium and bold;
  "letter-spacing": 0.2(px) - this is calculated as pixels to rem;
*/

@mixin Typography($array) {
  @each $break, $value in $array {
    @if $break == "mobile" {
      @include breakpointStyles($value);
    } @else {
      @include MQ-up($break) {
        @include breakpointStyles($value);
      }
    }
  }
}
