table {
  width: 100%;
  text-align: left;

  h2 {
    margin: 21px 0 14px;
    font-weight: 500;
    text-align: center;
  }

  h3 {
    margin-bottom: 0;
    font-weight: 500;
    text-align: center;
  }

  p {
    width: 90%;
    margin: 14px auto;
    text-align: center;
  }

  tr:nth-child(even) {
    background-color: var(--color-xxl-light-gray);
  }

  th {
    padding: 4px 7px;
    color: var(--color-xxl-white);
    background-color: var(--color-xxl-black);
  }

  td {
    padding: 4px 7px;
  }

  img {
    width: 100%;
    margin: 14px 0;
  }

  ul,
  ol {
    padding: 0;
    font-size: 1rem;
    list-style-position: inside;

    li {
      &:before {
        padding-right: 8px;
        content: "";
      }
    }
  }
}
