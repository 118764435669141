.top-usp {
  width: 100%;
  height: 28px;
  background-color: $color-xxl-green;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
  }

  li {
    display: inline-flex;
    align-items: center;
    height: 100%;
    margin-left: var(--space-small-regular);
    text-align: left;

    &:first-child {
      margin-left: 0;
    }

    @include max-screen("tablet") {
      justify-content: center;
      width: 100%;
    }

    svg,
    [data-inline-svg] {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: var(--space-micro);
      vertical-align: middle;
    }

    a {
      font-size: inherit;
      font-family: inherit;
      line-height: inherit;
      letter-spacing: inherit;
      text-decoration: underline;
    }
  }

  .top-usp__mobile {
    @include max-screen("tablet") {
      display: none;
    }
  }
}

.top-usp__top-message {
  color: $color-xxl-web-black;
  font-weight: 400;
  font-size: 10px;
  font-family: var(--font-family-regular);
  line-height: 1.2;
}

.top-usp__bottom-message {
  margin-left: var(--space-line);
  color: $color-xxl-web-black;
  font-weight: 400;
  font-size: 10px;
  font-family: var(--font-family-regular);
  line-height: 1.2;
}
