@use "sass:math";

@function strip-unit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function to-rem($val) {
  @return ($val + 0rem);
}

@function px-to-rem($px) {
  @return to-rem(math.div(strip-unit($px), strip-unit(16px)));
}

$font-size-tiny: px-to-rem(10);
$font-size-small: px-to-rem(12);
$font-size-bigger-small: px-to-rem(14);
$font-size-small-regular: px-to-rem(15);
$font-size-regular: px-to-rem(16);
$font-size-large: px-to-rem(20);
$font-size-extra-large: px-to-rem(32);
