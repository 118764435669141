$banner-text: (
  "mobile": (
    "font-size": 24,
    "font-family": bold,
    "line-height": 1.2,
  ),
  "laptop": (
    "font-size": 34,
  ),
);

$banner-description: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
  ),
);

$banner-grid-button: (
  "mobile": (
    "font-size": 12,
    "line-height": 1.3,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 15,
    "line-height": 1.2,
  ),
);

$banner-type: (
  "mobile": (
    "font-size": 12,
    "line-height": 1.2,
  ),
);

$text-banner-heading: (
  "mobile": (
    "font-size": 20,
    "font-family": bold,
    "line-height": 1.2,
  ),
  "laptop": (
    "font-size": 26,
  ),
);

$text-banner-description: (
  "mobile": (
    "font-family": regular,
    "font-size": 12,
    "line-height": 1.3,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 15,
    "line-height": 1.2,
  ),
);

$text-banner-button: (
  "mobile": (
    "font-family": bold,
    "font-size": 12,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$banner-product-info: (
  "mobile": (
    "font-size": 10px,
  ),
  "tablet": (
    "font-size": 15px,
  ),
);

$small-product-banner-cheapest-price: (
  "mobile": (
    "font-size": 10,
    "line-height": 1.8,
  ),
  "tablet": (
    "line-height": 1.5,
    "font-size": 12,
  ),
);

$banner-right-padding-sm: 80px;
$banner-right-padding: 200px;

$hero-component-right-padding-sm: $banner-right-padding-sm;
$hero-component-right-padding: 40%;

$xs-banner-padding: 12px;

$small-banner-padding: 12px;
$small-banner-padding-mobile: 8px;

$medium-banner-desktop-height: 572px;
$medium-banner-padding: 24px;
$medium-banner-padding-mobile: 12px;
