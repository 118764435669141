@import "../theme/typography.theme";

h1,
h2,
h3,
h4,
h5,
h6 {
  @include Typography($global-styles);
  @include Typography($header-styles);
}

h1 {
  @include Typography($h1-styles);
}

// In cases where we need a h1 element to be semantically correct, but want it to be the size of h3
h1.h1ash3 {
  @include Typography($h3-styles);
}

h2 {
  @include Typography($h2-styles);
}

h3 {
  @include Typography($h3-styles);
}

h4 {
  @include Typography($h4-styles);
}

h5 {
  @include Typography($h5-styles);
}

h6 {
  @include Typography($h6-styles);
}

p {
  @include Typography($global-styles);
  @include Typography($p-styles);
}

strong {
  @include Typography($global-styles);
  @include Typography($p-styles);
  @include Typography($strong-styles);
}

a {
  @include Typography($global-styles);
  @include Typography($anchor-styles);
}
