$global-styles: (
  "mobile": (
    "font-family": regular,
    "font-style": normal,
    "font-size": 16,
    "line-height": 18px,
    "letter-spacing": -0.15,
    "text-decoration": none,
  ),
) !default;

$header-styles: (
  "mobile": (
    "font-family": bold,
    "font-style": normal,
    "letter-spacing": -0.15,
  ),
) !default;

$h1-styles: (
  "mobile": (
    "font-size": 36,
    "line-height": 1,
  ),
  "laptop": (
    "font-size": 60,
  ),
) !default;

$h2-styles: (
  "mobile": (
    "font-size": 26,
    "line-height": 1,
  ),
  "laptop": (
    "font-size": 34,
  ),
) !default;

$h3-styles: (
  "mobile": (
    "font-size": 20,
    "line-height": 1.25,
  ),
  "laptop": (
    "font-size": 24,
  ),
) !default;

$h4-styles: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
  ),
) !default;

$h5-styles: (
  "mobile": (
    "font-size": 12,
    "line-height": 1.2,
  ),
) !default;

$h6-styles: (
  "mobile": (
    "font-size": 10,
    "font-family": regular,
    "line-height": 1.2,
  ),
  "laptop": (
    "font-size": 12,
  ),
) !default;

$p-styles: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
  ),
) !default;

$strong-styles: (
  "mobile": (
    "font-family": medium,
    "letter-spacing": -0.15,
  ),
) !default;

$anchor-styles: (
  "mobile": (
    "color": var(--font-color-primary),
  ),
) !default;
