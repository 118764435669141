/* Slider */
.slick-slider {
    height: inherit;
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    &.product-list {
        display: flex;
        flex-direction: column;
    }

    .slick-arrow {
        background-color: transparent;
        use {
            color: var(--font-color-primary-invert);
        }
    }

    .slick-dots {
        bottom: 18px;
        left: 24px;
        @include MQ-up("tablet") {
          bottom: 39px;
          left: 66px;
        }

        .slick-active button {
            background-color: var(--color-xxl-white);
        }
    }

    &--color-theme-black {
        .slick-arrow use {
            color: var(--color-xxl-web-black);
        }
        .slick-dots .slick-active button {
            background-color: var(--color-xxl-web-black);
        }
    }
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: flex;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    min-height: 1px;
    outline: none; /* Overriden from default */
    [dir="rtl"] & {
        float: right;
    }
    &:not(.slick-active){
        h1{
            display: none;
        }
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}
.slick-arrow {
    height: 45px;
    width: 45px;
    background-color: var(--color-xxl-white);
    color: #000;
    position: absolute;
    text-decoration: none;
    text-align: center;
    z-index: 1;
}
.slick-disabled {
    display: none !important;
}

.slick-slider:not(.js-one-item-carousel) {
    $arrowOffset: -43px;

    .slick-dots {
        position: initial; 
        display: flex !important; // has to override slick's "display: block" inline style
        margin-top: var(--space-regular);

        li {
            height: 0;
            width: 100%;
            margin: 0;

            button {
                height: 4px;
                width: 100%;
                border-radius: 0;
                padding: 0;
                border: 0;
                background-color: $color-xxl-light-gray;
            }

            &.slick-active {
                button {
                    background-color: $color-xxl-web-black;
                }
            }
        }
    }

    .slick-arrow {
        background-color: transparent;
    }

    .slick-next {
        right: $arrowOffset;
    }

    .slick-prev {
        left: $arrowOffset;
    }
}
