.guide-teaser {
  height: 100%;
  &__image {
    width: 100%;
  }
  &__details {
    margin-bottom: var(--space-regular);
    color: $color-xxl-web-black;
  }
  &__title {
    @include Typography($header-styles);
    @include Typography($h3-styles);
    margin: var(--space-small-regular) 0;
  }
  &__preamble {
    $lineHeight: 1.2;
    min-height: #{$lineHeight * 3}em;
    margin: 0;
    margin-right: var(--space-big);
    line-height: $lineHeight;
  }
}
