$baseHeight: 70px;

.mini-banner {
  width: 100%;
  min-height: $baseHeight;

  .mini-banner {
    &--has-countdown {
      height: 85px;
      @include MQ-up("laptop") {
        // todo: match against react breakpoints
        height: $baseHeight;
      }
    }
  }

  &__skeleton {
    @include skeleton;
  }
}
