$typography: (
  "mobile": (
    "font-family": regular,
    "font-size": 12,
    "line-height": px-to-rem(16),
    "letter-spacing": -0.15,
  ),
);

.info-messages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & a {
    @include Typography($typography);
    color: $color-xxl-web-black;
    white-space: nowrap;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  &__message {
    @include Typography($typography);
    width: 100%;
    margin: 0;
    padding: 6px 0;
    color: $color-xxl-web-black;
    text-align: center;
    background-color: $color-xxl-green;
    a {
      color: $color-xxl-web-black;
    }

    &--campaign {
      background-color: $color-xxl-orange;
    }

    &--reward {
      color: $color-xxl-white;
      background-color: $color-xxl-red;
      a {
        color: $color-xxl-white;
      }
    }
  }
}
