.long-tail-title {
  display: flex;
  flex-wrap: wrap;
  margin-top: unset;
  margin-bottom: var(--space-regular);
  font-weight: unset;
  font-family: var(--font-family-regular);
  line-height: unset;
  letter-spacing: 0.03rem;

  @include MQ-up("laptop") {
    margin-bottom: var(--space-large);
  }

  * {
    font-size: 1.25rem;

    @include MQ-up("laptop") {
      font-size: 1.625rem;
    }
  }

  &__brand {
    width: 100%;
    margin-bottom: var(--space-micro);
    & > a {
      text-decoration: underline;
    }

    @include MQ-up("laptop") {
      margin-bottom: var(--space-mini);
    }
  }

  span {
    &:last-of-type {
      &::after {
        content: "";
      }
    }
  }

  &__category {
    font-family: var(--font-family-bold);
  }

  &__color,
  &__category,
  &__size {
    margin-right: var(--space-micro);
    margin-left: 0;

    &::after {
      content: ",";
    }
  }
}
