$button-typography: (
  "mobile": (
    "font-family": medium,
    "font-size": 15,
    "line-height": 1,
    "letter-spacing": -0.15,
  ),
);
$desktopButtonHeight: 66px;
$mobileButtonHeight: 58px;

.button {
  // Unified button styles, every button type must have hover & disabled state.
  // common styles
  @include Typography($button-typography);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: fit-content;
  min-width: 110px;
  padding: 0 var(--space-large);
  overflow: hidden;
  border: none;
  outline: none;
  transition: 0.2s ease;

  @include MQ-up("laptop") {
    min-width: 125px;
  }

  &:disabled {
    @include button-disabled;
  }
  &--disabled {
    @include button-disabled;
    color: $color-xxl-gray;
  }

  // Grid adherence (responsive, full-width)

  &--full-width {
    width: 100%;
  }

  &--square {
    min-width: unset;
  }

  // Sizes (Large, Small)

  &--large {
    height: $mobileButtonHeight;
    min-height: $mobileButtonHeight;

    @include MQ-up("laptop") {
      height: $desktopButtonHeight;
      min-height: $desktopButtonHeight;
    }
  }

  &--small {
    height: 45px;

    @include MQ-up("laptop") {
      height: 50px;
    }
  }

  &--table-pagination {
    display: inline-block;
    width: 42px;
    min-width: auto;
    height: 42px;
    padding: 0;
    text-align: center;
    vertical-align: middle;

    & svg {
      width: 12px;
      height: 20px;
    }
  }

  &--small-action {
    width: var(--space-huge);
    min-width: auto;
    height: calc(var(--space-regular) * 2);
    padding: 0;
  }

  // Colors (Primary, Secondary, Accent)

  &--primary {
    color: $color-xxl-white;
    background-color: $color-xxl-black;

    &:disabled {
      color: $color-xxl-gray;
    }
  }

  &--secondary {
    color: $color-xxl-web-black;
    background-color: $color-xxl-white;
  }

  &--outlined {
    border: 1px solid $color-xxl-gray;

    &.button--primary {
      color: $color-xxl-white;
      background-color: transparent;
      border: 1px solid $color-xxl-white;
    }
  }

  &--black {
    color: $color-xxl-white;
    background-color: $color-xxl-black;
    border-color: $color-xxl-black;
    cursor: pointer;

    &.button--inverted {
      color: $color-xxl-black;
      background-color: transparent;
    }
  }

  &--accent {
    color: $color-xxl-web-black;
    background-color: $color-xxl-green;
    @include button-hover($color-xxl-web-black, $color-xxl-green);
  }

  &--primary,
  &--secondary {
    @include button-hover();
  }

  // Icon options (with, without)

  &--with-icon svg {
    width: var(--space-small-regular);
    height: var(--space-small-regular);
    margin-right: var(--space-mini);
  }

  &__icon--small {
    width: var(--space-mini);
    height: var(--space-mini);
  }
}
