$product-price-strong: (
  "mobile": (
    "font-family": bold,
    "font-size": 36,
    "line-height": 1,
  ),
  "laptop": (
    "font-size": 34,
  ),
);

$product-price-label: (
  "mobile": (
    "font-size": 12,
    "line-height": 1.3,
  ),
  "laptop": (
    "font-size": 15,
  ),
);

$product-image-label: (
  "mobile": (
    "font-family": var(--font-family-bold),
    "font-size": 12,
    "line-height": 1.2,
    "font-weight": 700,
  ),
);

$product-previous-price: (
  "mobile": (
    "font-family": bold,
    "font-size": 15,
    "line-height": 1.2,
    "text-decoration": line-through,
  ),
  "laptop": (
    "font-size": 24,
  ),
);

$product-inverted-price: (
  "mobile": (
    "font-size": 18,
  ),
);

$product-name: (
  "mobile": (
    "font-family": bold,
    "line-height": 1.1,
    "font-size": 20,
    "text-transform": none,
    "vertical-align": top,
    "word-break": break-word,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 34,
    "line-height": 1,
  ),
);

$bundle-headline: (
  "mobile": (
    "font-family": bold,
    "font-size": 26,
    "letter-spacing": -0.26,
  ),
  "laptop": (
    "font-size": 60,
    "line-height": -1.2,
  ),
);

$bundle-step-info: (
  "mobile": (
    "font-family": bold,
    "line-height": 1.1,
    "font-size": 15,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 26,
    "line-height": 1.2,
  ),
);

$bundle-step-title: (
  "mobile": (
    "font-family": bold,
    "line-height": 1.1,
    "font-size": 20,
    "letter-spacing": -0.15,
  ),
  "laptop": (
    "font-size": 26,
    "line-height": 1.2,
  ),
);

$bundle-price: (
  "mobile": (
    "font-family": bold,
    "font-size": 36,
    "letter-spacing": -0.36,
  ),
  "laptop": (
    "font-size": 34,
    "line-height": -0.34,
  ),
);

$product-add-to-cart: (
  "mobile": (
    "font-family": regular,
    "font-size": 15,
    "line-height": 1.2,
  ),
);

$product-brand-name: (
  "mobile": (
    "font-family": regular,
    "font-size": 12,
    "line-height": 1.3,
    "white-space": nowrap,
    "text-decoration": none,
  ),
);

$product-promotion-message-header: (
  "mobile": (
    "font-family": bold,
    "font-size": 26,
    "line-height": 1,
  ),
  "laptop": (
    "line-height": 1.2,
  ),
);

$product-promotion-message-text: (
  "mobile": (
    "font-size": 12,
    "line-height": 1.3,
  ),
);

$product-promotion-message-link: (
  "mobile": (
    "font-size": 12,
    "font-family": medium,
    "line-height": 1.2,
    "text-decoration": none,
  ),
);

$product-info-message: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-size-guide: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-stock-usps: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-select-box-label: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-select-box-tooltip: (
  "mobile": (
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-select-box-tooltip-strong: (
  "mobile": (
    "font-family": bold,
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

$product-bundle-footer-heading: (
  "mobile": (
    "font-size": 26,
    "line-height": 1,
    "letter-spacing": -0.15,
  ),
  "tablet": (
    "font-size" 60,
  ),
);
