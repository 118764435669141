$category-headers: (
  "mobile": (
    "font-size": 36,
  ),
  "tablet": (
    "font-size": 50,
  ),
  "laptop": (
    "font-size": 90,
  ),
);

$category-description: (
  "mobile": (
    "font-family": regular,
    "font-size": 15,
    "line-height": 1.2,
    "letter-spacing": -0.15,
  ),
);

.category-list-header {
  h1 {
    @include Typography($h3-styles);
  }

  margin-bottom: 21px;

  @include max-screen("mobile") {
    margin: 14px 0;
    padding: 0 10px;
  }
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  overflow: visible;

  @include max-screen("mobile") {
    display: flex;
    flex-direction: column;
  }

  .category-list__product-list {
    & > .product-list {
      min-height: 0;
    }
  }

  &.category-list--no-aside {
    .category-list__product-list {
      width: 100%;
    }

    .product-list {
      @include MQ-up("tablet") {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }
      @include MQ-up("laptop") {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
      @include MQ-up("desktop") {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }
}

.category-list__aside {
  position: relative;
  float: left;
  width: calc(25% - 21px);
  max-width: 340px;
  margin-right: 21px;

  @include max-screen("mobile") {
    order: 1;
    float: none;
    width: 100%;
    max-width: inherit;
    margin-right: 0;
  }

  img {
    max-width: 95%;
  }

  figure {
    margin-bottom: 14px;
  }
}

.category-list__sticky-wrapper {
  @include max-screen("mobile") {
    padding-bottom: 14px;
  }
  &--with-description {
    margin-top: 21px; /* Need to have margin-top here since the description is inline (margin won't work). */
  }
}

.category-list__product-list {
  position: relative;
  float: left;
  width: 75%;
  text-align: center;

  @include max-screen("mobile") {
    order: 3;
    width: 100%;
  }

  @include max-screen("mobile") {
    width: 100%;
  }

  &.new-plp-filters {
    width: 100%;
  }
}

.category-list__description {
  @include Typography($category-description);
  margin: 0;

  & > p {
    display: inline;
    margin: 0;
  }

  a {
    color: var(--font-color-primary);
    text-decoration: underline;
    cursor: pointer;
  }

  @include max-screen("mobile") {
    float: none;
    width: 100%;
  }
}

.category-list__read-more {
  margin-bottom: 21px;
  color: var(--font-color-primary);
  font-family: var(--font-family-medium);
  text-decoration: underline;
  cursor: pointer;
}

.category-list__load-more {
  position: relative;
  width: 320px;
  margin: 21px auto;
}

.category-long-description {
  // Content-api returns content wrapped in a single div
  div {
    * {
      margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 1.2em;
    @include MQ-up("tablet") {
      max-width: 67%;
    }
  }
}
