@mixin sharedGuideCarouselStyling($type) {
  &__slick-wrapper:not(.slick-initialized) {
    // CSS to prevent CLS
    display: flex;
    padding-bottom: 15px;
    visibility: hidden;
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);
    @include MQ-down("small-desktop") {
      display: none !important;
    }
    & use {
      color: $color-xxl-web-gray;
    }
    svg {
      background-color: $color-xxl-web-black;
      color: white;
    }
  }

  .slick-dots {
    @include MQ-up("small-desktop") {
      // "display: none" may cause render issues when switching between breakpoints
      height: 0;
      visibility: hidden;
    }
  }

  $slideGap: calc(var(--space-small-regular) / 2);
  .slick-list {
    margin: 0 calc($slideGap * -1);
  }

  .#{$type}-teaser {
    display: flex;
    flex-direction: column;
    margin: 0 $slideGap;
  }
}

.guide-carousel {
  @include sharedGuideCarouselStyling(guide);
  &__slick-wrapper:not(.slick-initialized) {
    .guide-teaser__image {
      width: calc(100vw - var(--space-large));
      @include MQ-up("tablet") {
        width: calc(50vw - var(--space-large));
      }
      @include MQ-up("laptop") {
        width: 435px;
      }
    }
  }

  & .slick-track {
    gap: var(--space-small-regular);
  }

  .guide__footer {
    margin-top: auto;
  }
}
