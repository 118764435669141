@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-arrow-color: black !default;
$slick-dot-color: white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "<" !default;
$slick-next-character: ">" !default;
$slick-dot-character: "" !default;
$slick-dot-size: 6px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

/* Arrows */

.slick-prev,
.slick-next {
  display: block;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  &:hover,
  &:focus {
    outline: none;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .icons {
    position: relative;
    width: 24px;
    height: 24px;
  }
}

.slick-prev {
  left: 11px;
  [dir="rtl"] & {
    left: auto;
    right: -25px;
  }
  .icons {
    transform: rotate(180deg);
  }
}

.slick-next {
  right: 11px;
  [dir="rtl"] & {
    left: -25px;
    right: auto;
  }
}

/* Dots */

.slick-dots {
  position: absolute;
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    display: inline-block;
    height: 6px;
    width: 6px;
    margin-right: var(--space-micro);
    padding: 0;
    cursor: pointer;

    @include MQ-up("tablet") {
      height: 12px;
      margin-right: var(--space-mini);
      width: 12px;
    }

    button {
      background: rgba(255, 255, 255, 0.4);
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      box-sizing: border-box;
      height: 100%;
      width: 100%;
      border-radius: 50%;
      display: block;
      border: none;
      border-style: solid;
      transition: all 200ms;

      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
    }

    &.slick-active button {
      background-color: var(--font-color-primary-invert);
    }
  }
}
