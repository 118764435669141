@use "sass:math";

/// Mixin to manage responsive breakpoints
/// @author Hugo Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints

/// article
/// https://glennmccomb.com/articles/useful-sass-scss-media-query-mixins-for-bootstrap/

@mixin respond-to($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media only screen and (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin respond-to-device($device) {
  @if $device == "mobile" {
    @media (hover: none) and (pointer: coarse) {
      @content;
    }
  } @else {
    @media (hover: hover) and (pointer: fine),
      (hover: hover) and (pointer: coarse) {
      @content;
    }
  }
}

@mixin touch() {
  @media screen and (orientation: landscape) and (pointer: fine) and (max-height: map-get($breakpoints, "mobile")) {
    @content;
  }
  html.emulate-touch & {
    @content;
  }
}

@mixin max-screen($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @if $breakpoint == "mobile" {
      // Handle also landscape-oriented mobile
      @media only screen and (max-width: map-get($breakpoints, $breakpoint)),
        only screen and (hover: none) and (pointer: coarse) and (max-height: map-get($breakpoints, "tablet-height")) and (orientation: landscape) {
        @content;
      }
    } @else {
      @media only screen and (max-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin MQ($breakpoint) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media only screen and (min-width: map-get($breakpoints, $breakpoint) + 1) {
      @content;
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($breakpoints)}.';
  }
}

@mixin respond-between($lower, $upper) {
  // If both the lower and upper breakpoints exist in the map.
  @if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
    // Get the lower and upper breakpoints.
    $lower-breakpoint: map-get($breakpoints, $lower);
    $upper-breakpoint: map-get($breakpoints, $upper);

    // Write the media query.
    @media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
      @content;
    }

    // If one or both of the breakpoints don't exist.
  } @else {
    // If lower breakpoint is invalid.
    @if (map-has-key($breakpoints, $lower) == false) {
      // Log a warning.
      @warn 'Your lower breakpoint was invalid: #{$lower}.';
    }

    // If upper breakpoint is invalid.
    @if (map-has-key($breakpoints, $upper) == false) {
      // Log a warning.
      @warn 'Your upper breakpoint was invalid: #{$upper}.';
    }
  }
}

@mixin FF-fix() {
  @-moz-document url-prefix() {
    @content;
  }
}

//Background color transition on hover
@mixin hover-background($base-color, $animation-color) {
  background-color: $base-color;

  @include respond-to-device("mobile") {
    -webkit-tap-highlight-color: transparent;
    &:active {
      background-color: $animation-color;
    }
  }

  @include respond-to-device("desktop") {
    transition: all 0.2s ease;
    &:hover {
      background-color: $animation-color;
    }
  }

  &:focus {
    outline: none;
  }

  &.active {
    background-color: $animation-color;
    pointer-events: none;
  }
}

//Button hover and focus transition
@mixin button-hover(
  $background-color: $color-xxl-green,
  $text-color: $color-xxl-web-black
) {
  transition: all 0.2s ease;
  &:active:not(:disabled),
  &:hover:not(:disabled) {
    color: $text-color;
    background-color: $background-color;
    border-color: $background-color;
    cursor: pointer;
  }

  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: $color-xxl-green;
    outline-offset: 2px;
  }
}

@mixin button-disabled() {
  cursor: auto;
  opacity: 0.4;
  pointer-events: none;
}

//Underline transition on hover
@mixin hover-underline($underline-color, $width: 100%) {
  position: relative;

  &::before {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    border-bottom: 4px solid $underline-color;
    transform: skew(-30deg);
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    content: "";
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;

    &::before {
      width: $width;
    }
  }
}

@mixin hover-underline-active($underline-color) {
  position: relative;
  text-decoration: none;

  &::before {
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    border-bottom: 4px solid $underline-color;
    transform: skew(-30deg);
    -webkit-transition: width 0.2s;
    transition: width 0.2s;
    content: "";
  }
}

@mixin hover-underline-linear($underline-color-hover) {
  position: relative;

  &::before {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    border-bottom: 3px solid $underline-color-hover;
    -webkit-transition: width 0.2s linear;
    transition: width 0.2s linear;
    content: "";
  }

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;

    &::before {
      left: 0;
      width: 100%;
    }
  }

  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 0;
    border-bottom: 3px solid $underline-color-hover;
    -webkit-transition: width 0.2s linear;
    transition: width 0.2s linear;
    content: "";
  }
}

@mixin hover-underline-linear-active($underline-color-active, $height) {
  &::after {
    position: absolute;
    bottom: -3px;
    left: 0;
    width: 100%;
    border-bottom: $height solid $underline-color-active;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    content: "";
  }
}

@mixin animated-checkmark($size, $thickness, $color, $background-color) {
  $loader-size: $size;
  $check-height: math.div($loader-size, 2);
  $check-width: math.div($check-height, 2);
  $check-thickness: $thickness;
  $check-color: $color;

  position: relative;

  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $background-color;

    &.draw:after {
      transform: scaleX(-1) rotate(135deg);
      animation-name: checkmark;
      animation-duration: 800ms;
      animation-timing-function: ease;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: calc(50% - (#{$check-width} + #{$check-thickness}));
      z-index: 99;
      width: $check-width;
      height: $check-height;
      border-top: $check-thickness solid $check-color;
      border-right: $check-thickness solid $check-color;
      transform: scaleX(-1) rotate(135deg);
      transform-origin: left top;
      opacity: 1;
      content: "";
    }
  }

  @keyframes checkmark {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    20% {
      width: $check-width;
      height: 0;
      opacity: 1;
    }
    40% {
      width: $check-width;
      height: $check-height;
      opacity: 1;
    }
    100% {
      width: $check-width;
      height: $check-height;
      opacity: 1;
    }
  }
}

@mixin animated-checkmark-large($size, $thickness, $color, $background-color) {
  $loader-size: $size;
  $check-height: $loader-size/2;
  $check-width: $check-height/2;
  $check-thickness: $thickness;
  $check-color: $color;

  position: relative;

  .checkmark {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $background-color;

    &.draw:after {
      transform: scaleX(-1) rotate(135deg);
      animation-name: checkmark-large;
      animation-duration: 800ms;
      animation-timing-function: ease;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: calc(50% - (#{$check-width} + #{$check-thickness}));
      z-index: 99;
      width: $check-width;
      height: $check-height;
      border-top: $check-thickness solid $check-color;
      border-right: $check-thickness solid $check-color;
      transform-origin: left top;
      opacity: 1;
      content: "";
    }
  }

  @keyframes checkmark-large {
    0% {
      width: 0;
      height: 0;
      opacity: 1;
    }
    20% {
      width: $check-width;
      height: 0;
      opacity: 1;
    }
    40% {
      width: $check-width;
      height: $check-height;
      opacity: 1;
    }
    100% {
      width: $check-width;
      height: $check-height;
      opacity: 1;
    }
  }
}

@mixin pulsate-animation($delay: 0s, $period: 3s, $factor: 1.5) {
  @keyframes rotate {
    from {
      transform: scale(1);
    }
    50% {
      transform: scale($factor);
    }
    to {
      transform: scale(1);
    }
  }
  animation-name: rotate;
  animation-duration: $period;
  animation-timing-function: linear;
  animation-delay: $delay;
  animation-iteration-count: infinite;
}

/**
* Fade the left or right edge of an element to transparent.
* Appends a pseudo :before (left) or :after (right) element to parent.
* Overlaps the parent element with a gradient background at z-index 2.
*/
@mixin fade-element-edge(
  $height: 100%,
  $width: 16%,
  $direction: "right",
  $toColor: var(--color-xxl-white)
) {
  $element: "after";
  @if $direction == "left" {
    $element: "before";
  }

  &:#{$element} {
    position: absolute;
    z-index: 2;
    width: $width;
    height: $height;
    background: linear-gradient(to #{$direction}, transparent, $toColor);
    content: "\00a0";
    #{$direction}: calc(#{$width} / 2);
  }
}

@mixin remove-default-button-styling {
  margin: 0;
  padding: 0;
  font-size: 100%;
  font-family: inherit;
  line-height: 1;
  background-color: transparent;
  border: 0;
  outline: none;
  cursor: pointer;

  &:active {
    border: 0;
  }

  &:focus {
    outline: unset;
  }
}

@mixin visuallyhidden() {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
  pointer-events: none;
}

@mixin MQ-up($breakpoint) {
  @if map-has-key($new-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media only screen and (min-width: map-get($new-breakpoints, $breakpoint) + 1) {
      @content;
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($new-breakpoints)}.';
  }
}

@mixin MQ-down($breakpoint) {
  @if map-has-key($new-breakpoints, $breakpoint) {
    // Prints a media query based on the value
    @media only screen and (max-width: map-get($new-breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    // If the key doesn't exist in the map
    @warn 'Unfortunately, no value could be retrieved from `#{$breakpoint}`. '
    + 'Available breakpoints are: #{map-keys($new-breakpoints)}.';
  }
}

@mixin MQ-screen() {
  @media only screen {
    @content;
  }
}

@import "../theme/site-header.theme";

@mixin search-dropdown-header {
  @include Typography($search-dropdown-header);
  margin: 0 0 var(--space-large);
  cursor: default;

  &:not(:first-of-type) {
    margin-top: var(--space-big);
  }
}

@mixin search-dropdown-button {
  @include Typography($search-dropdown-button);
  position: relative;
  display: block;
  margin-top: var(--space-big);
  padding: var(--space-mini) var(--space-large) var(--space-mini)
    var(--space-small-regular);

  @include max-screen("laptop") {
    width: auto;
    height: auto;
  }

  @include MQ-up("laptop") {
    padding-left: var(--space-big);
  }

  @include MQ-up("desktop") {
    padding-left: var(--space-small-huge);
  }

  &::after {
    position: absolute;
    top: 50%;
    right: 12px;
    display: block;
    width: 4px;
    height: 4px;
    border-top: 1px solid currentColor;
    border-right: 1px solid currentColor;
    transform: rotate(45deg) translateY(-50%);
    transition: all 0.2s ease;
    content: "";
  }
}

@mixin search-dropdown-element {
  @include Typography($search-dropdown-element);
  padding: 0;
  color: $color-xxl-web-black;
  cursor: pointer;
}

@mixin gradientImageOverlay($zIndex: 0) {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: $zIndex;
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 65.93%,
      rgba(0, 0, 0, 0.32) 100%
    );
    content: "";
  }
}

@mixin quantityInput {
  input {
    width: 48px;
    height: 48px;
    margin: 0 var(--space-regular);
    text-align: center;
    vertical-align: middle;
    border: 1px solid var(--color-xxl-light-gray);
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@mixin keyboardFocus {
  &:focus-visible {
    outline-width: 2px;
    outline-style: solid;
    outline-color: $color-xxl-green;
    outline-offset: 2px;
  }
}

@mixin discountedPrice {
  &--discounted {
    color: var(--color-xxl-red);
  }
}

@mixin interactionFeedback() {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
  @media (hover: none) {
    &:active {
      @content;
    }
  }
  &:focus-visible {
    @content;
  }
}

@mixin outline-for-tabs($size: 2px, $color: $color-xxl-green, $outline: 5px) {
  outline: $size solid $color;
  outline-offset: $outline;
}

@mixin skeleton {
  $firstColor: var(--color-xxl-light-gray);
  $secondColor: var(--color-xxl-gray);

  width: 100%;
  height: 100%;
  background-color: $firstColor;
  animation: skeleton 2s infinite;

  @keyframes skeleton {
    0% {
      background-color: $firstColor;
    }
    50% {
      background-color: $secondColor;
    }
    100% {
      background-color: $firstColor;
    }
  }
}

@mixin ribbon-left($height) {
  border-top-width: $height;
  border-left: calc($height / $sqrt3) solid transparent;
}

@mixin ribbon-right($height) {
  border-top-width: $height;
  border-right: calc($height / $sqrt3) solid transparent;
}

// Take into consideration xxlScrollbars(width) helper in helpers.tsx when changing @mixin xxl-scrollbar($width)
@mixin xxl-scrollbar($width) {
  scrollbar-color: var(--color-xxl-black-light) var(--color-xxl-medium-grey);
  scrollbar-width: $width;

  &::-webkit-scrollbar-thumb {
    background: var(--color-xxl-black-light);
  }
  &::-webkit-scrollbar-track {
    background: var(--color-xxl-medium-grey);
  }

  &::-webkit-scrollbar {
    width: $width;
    height: $width;
  }
}
