$itemHeightWidthDesktop: 122px;
$itemHeightWidthMobile: 104px;
$itemBorderBottom: 2px;

.category-selector {
  margin: var(--space-large) auto;

  @include MQ-up("laptop") {
    margin: var(--space-big) auto;
  }

  &__list-items {
    display: grid;
    grid-template-columns: repeat(auto-fit, $itemHeightWidthMobile);
    gap: 6px;
    justify-content: flex-start;
    margin: 0;
    padding: 0;
    list-style-type: none;

    @include MQ-up("laptop") {
      grid-template-columns: repeat(auto-fit, $itemHeightWidthDesktop);
      gap: var(--space-small-regular);
    }
  }

  &__item {
    min-height: var(--space-large);

    &--selected {
      border-bottom: 1px solid var(--color-xxl-black);
    }

    @include MQ-up("laptop") {
      border: 0;
    }
  }

  &__link {
    @include keyboardFocus;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: $itemHeightWidthMobile;
    height: $itemHeightWidthMobile;
    padding: var(--space-mini);
    color: var(--color-xxl-black);
    font-size: px-to-rem(15);
    font-family: var(--font-family-regular);
    line-height: 1.2;
    text-align: center;
    word-break: break-word;
    background-color: var(--color-xxl-light-gray);
    border: 0;
    user-select: none;

    &--clickable {
      @include interactionFeedback {
        color: var(--color-xxl-black);
        background-color: var(--color-xxl-green);
        cursor: pointer;
      }
    }

    @include MQ-up("laptop") {
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      width: $itemHeightWidthDesktop;
      height: $itemHeightWidthDesktop;
      padding: var(--space-mini);
      font-size: px-to-rem(15);
      background-color: var(--color-xxl-light-gray);
    }

    &--selected {
      color: var(--color-xxl-white);
      font-family: var(--font-family-bold);
      background-color: var(--color-xxl-black);

      &:not(:hover) {
        img {
          filter: invert(1);
        }
      }
    }
  }

  &__icon {
    width: $mobileIconSize;
    max-height: $mobileIconSize;
    margin: 0 0 4px;

    &--invert {
      color: var(--color-xxl-white);
    }
    svg {
      font-size: px-to-rem(32);
    }

    @include MQ-up("laptop") {
      width: $desktopIconSize;
      max-height: $desktopIconSize;
      margin: 0 0 7px;

      svg {
        font-size: px-to-rem(48);
      }
    }
  }

  &__text {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include MQ-up("laptop") {
      overflow: visible;
      white-space: normal;
      text-overflow: clip;
    }
  }
}
