$select-box-header-span: (
  "mobile": (
    "font-family": regular,
    "font-size": 15,
    "line-height": 1.2,
    "text-overflow": ellipsis,
    "letter-spacing": -0.15,
  ),
);

$select-box-warning: (
  "mobile": (
    "font-family": regular,
    "font-size": 12,
    "letter-spacing": -0.15,
    "text-align": right,
  ),
);
