@import "../theme/select-box.theme";

.select-box {
  position: relative;
  background-color: var(--color-xxl-white);
  outline: none;
  cursor: pointer;
}

.select-box--transparent {
  background-color: transparent;
}

.select-box__header {
  @include Typography($select-box-header-span);
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 50px;
  margin: 0;
  padding: 0 14px;
  border: 1px solid $color-xxl-gray;
  outline: none;

  @include MQ-up("laptop") {
    height: 50px;
  }

  .select-box--transparent & {
    border-color: transparent;
  }

  svg,
  [data-inline-svg] {
    z-index: 1;
    width: 8px;
    height: 4px;
    margin-left: 7px;
    transition: transform 200ms ease;
  }

  > span {
    @include Typography($select-box-header-span);
    display: inline-block;
    width: 95%;
    overflow: hidden;
    color: $color-xxl-web-gray;
  }

  &.select-box__bundle--selected {
    &::after {
      bottom: 0;
    }
  }

  .select-box__arrow-box {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    width: 46px;
    &--green {
      background-color: var(--color-xxl-green);
    }
  }
}

.select-box--transparent--white {
  .select-box__header-text {
    color: var(--color-xxl-white);
  }

  a {
    color: var(--color-xxl-black);
  }
}

.select-box__header-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.select-box__content {
  position: absolute;
  left: 0;
  z-index: 101;
  display: none;
  box-sizing: border-box;
  width: 100%;
  max-height: 331px;
  margin: 0 0 14px;
  padding: 0;
  overflow: auto;
  list-style-type: none;
  background-color: var(--color-xxl-white);
  border-right: 1px solid $color-xxl-gray;
  border-bottom: 1px solid $color-xxl-gray;
  border-left: 1px solid $color-xxl-gray;
  -webkit-overflow-scrolling: touch;
  &--teamsales-assortment {
    min-width: 250px;
  }
}

.select-box__item {
  @include Typography($select-box-header-span);
  display: flex;
  align-items: center;
  padding: 10px 14px;
  background-color: var(--color-xxl-white);
  cursor: pointer;
  transition: background-color 50ms;

  &:hover,
  &:focus {
    background-color: var(--color-xxl-light-gray);
    outline: none;
    transition: background-color 0ms;
  }

  a {
    color: var(--color-xxl-black);
    text-decoration: none;
  }
}

.select-box-items__item-size {
  flex: 1;
}

.select-box-items__item-low-qty {
  @include Typography($select-box-warning);
  margin-left: 10px;
  color: $color-xxl-orange;
}

.select-box__item-out-of-stock {
  @include Typography($select-box-warning);
  margin-left: 10px;
  color: $color-xxl-red;
}

.select-box__item--disabled {
  color: $color-xxl-gray;
}

.select-box--active {
  .select-box__header svg,
  .select-box__header [data-inline-svg] {
    /* Chrome and other webkit browsers */
    -webkit-transform: rotate(180deg);
    /* FF */
    -moz-transform: rotate(180deg);
    /* Opera */
    -o-transform: rotate(180deg);
    /* IE9 */
    -ms-transform: rotate(180deg);
    /* W3C compliant browsers */
    transform: rotate(180deg);
  }

  .select-box__content {
    display: block;
  }

  &::after {
    bottom: 0 !important;
  }
}

.select-box--error {
  @include hover-underline-linear-active($color-xxl-red, 1px);

  &::after {
    bottom: 0;
  }
}

.select-box__input-item {
  cursor: default;

  input {
    max-width: calc(100% - 28px);
    padding: 3px;
  }
  button {
    margin: 0;
    margin-left: 3px;
    padding: 5px;
    background: none;
    border: none;
    cursor: pointer;
  }
}

.select-box__error-message {
  display: none;
  float: left;
  margin: 7px 0;
  color: $color-xxl-red;
  font-size: 0.6rem;

  svg,
  [data-inline-svg] {
    margin-right: 5px;
  }

  span {
    line-height: 17px;
    vertical-align: top;
  }

  &:after {
    clear: both;
  }
}

.select-box__label {
  font-size: 0.7rem;
  line-height: 25px;
  letter-spacing: 0.35px;
}
