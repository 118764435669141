$height: 40px;
$xxl-green: var(--color-xxl-green);

.ribbon {
  display: flex;
  height: $height;
  margin-left: auto;
  background-color: transparent;

  .ribbon__content {
    display: flex;
    align-items: center;
    padding: 0 var(--space-small-regular);
    color: var(--color-xxl-black);
    font-size: px-to-rem(15);
    font-family: var(--font-family-medium);
    line-height: 1.2;
    white-space: nowrap;
    text-align: center;
    background-color: inherit;
    background-color: $xxl-green;
    border-color: $xxl-green;

    span,
    a {
      text-transform: lowercase;
      &::first-letter {
        text-transform: uppercase;
      }
    }
  }
}

.ribbon::before {
  width: 0;
  height: 0;
  border-top-color: $xxl-green;
  border-top-style: solid;
  @include ribbon-left($height);
  content: "";
}
