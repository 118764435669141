.category-page-hero {
  $hero-height-tablet: 550px;
  $hero-height-big-screens: 624px;
  $background-color: var(--backgroundColor);
  position: relative;
  display: grid;
  box-sizing: border-box;
  min-height: 232px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: $background-color;

  @include MQ-up("tablet") {
    grid-template-columns: 65% 35%;
    height: $hero-height-tablet;
  }

  @include MQ-up("small-desktop") {
    height: $hero-height-big-screens;
  }

  &--small {
    $hero-height: 440px;

    @include MQ-up("tablet") {
      height: $hero-height;
    }

    &::after {
      @include MQ-up("tablet") {
        border-width: $hero-height 135px 0 0;
      }
    }

    .category-page-hero__heading {
      @include MQ-down("desktop") {
        font-size: px-to-rem(36);
      }
    }
  }

  &--black {
    color: var(--font-color-primary);
  }

  &--white {
    color: var(--font-color-primary-invert);
  }

  &--force-black-text-on-mobile {
    @include MQ-down("tablet") {
      color: var(--font-color-primary);
    }
  }

  figure {
    grid-row: 1;
    height: 200px;
    overflow: hidden;

    @include MQ-up("tablet") {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: auto;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      @include MQ-up("tablet") {
        float: right;
      }
    }
  }

  &__details {
    position: relative;
    z-index: 2;
    padding: var(--space-large) var(--space-small-regular);
    color: currentColor;

    @include MQ-up("tablet") {
      padding: var(--space-big) var(--space-small-huge);
    }
  }

  &__heading {
    margin-top: 0;
    margin-bottom: var(--space-regular);
    color: currentColor;
    font-size: px-to-rem(36);
    line-height: 36px;

    @include MQ-up("tablet") {
      font-size: px-to-rem(60px);
      line-height: 54px;
    }

    @include MQ-up("desktop") {
      margin-bottom: var(--space-big);
      font-size: px-to-rem(90px);
      line-height: 84px;
    }
  }

  &__text {
    max-width: 435px;
    max-height: 63px;
    overflow: hidden;
    color: currentColor;
    line-height: 21px;
  }

  &__text-large {
    max-width: 435px;
    max-height: 147px;
    overflow: hidden;
    color: currentColor;
    line-height: 21px;
  }

  &--with-background-color {
    &::after {
      @include MQ-up("tablet") {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        box-sizing: border-box;
        width: calc(66% + var(--space-small-regular));
        border-color: $background-color transparent;
        border-style: solid;
        border-width: $hero-height-tablet 225px 0 0;
        content: "";
      }

      @include MQ-up("small-desktop") {
        width: calc(66% + var(--space-small-huge));
        border-width: $hero-height-big-screens 312px 0 0;
      }
    }
    figure {
      @include MQ-up("tablet") {
        width: 60%;
      }
    }
  }
}
