@import "../theme/guide-article-date.theme";

.guide {
  &__publication-date {
    @include Typography($date-styling);
    flex-shrink: 0;
    margin: 0 0 0 auto;
  }

  &__footer {
    display: flex;
    overflow: hidden;
    color: $color-xxl-web-gray;
  }

  &__table-wrapper {
    $FIRST_COLUMN_MIN_WIDTH: 300px;
    @include Typography($p-styles);
    display: inline-block;
    min-width: 100%;
    max-width: calc(100% + var(--space-small-regular));
    overflow: auto;

    @import "../helpers/guide-table";

    &--horizontal-price-table {
      table {
        tr {
          th:first-child {
            min-width: $FIRST_COLUMN_MIN_WIDTH;
          }
          &:last-child {
            font-family: var(--font-family-bold);
            white-space: nowrap;
            background-color: var(--color-xxl-gray);
          }
        }
      }
    }
    table {
      border-spacing: 0;
    }
  }

  &__tags {
    display: flex;
    align-items: center;
    max-width: 100%;
    margin: 0 14px 0 0;
    padding: 0;
    overflow: hidden;
    list-style: none;
    li {
      @include Typography($date-styling);
      margin-right: var(--space-micro);
      @include MQ-down("mobile") {
        &:nth-child(n + 3) {
          display: none;
        }
      }
    }
  }

  &__video {
    display: block;
  }
}
