$breakpoints: (
  "mobile-tiny": 320px,
  "mobile": 767px,
  "tablet-special": 1023px,
  //PDP product details accordion, product info part, site header, usps
  "tablet-height": 690px,
  //iPad usable area height
  "tablet": 1024px,
  "laptop": 1080px,
  "desktop": 1395px,
  "big-desktop": 1440px,
  "max-image": 1920px,
) !default;

/* 
  This breakpoints are prepared for mobile-first approach and can be used with MQ-up and MQ-down mixins
*/
$new-breakpoints: (
  "small-mobile": 319px,
  "mobile": 374px,
  "small-tablet": 499px,
  "tablet": 767px,
  "laptop": 1023px,
  "small-desktop": 1080px,
  "desktop": 1279px,
  "big-desktop": 1439px,
  "full-HD": 1919px,
  "4k": 3999px,
) !default;

$maxContainerWidth: 1332px;
$narrowContainerOffset: calc($maxContainerWidth / 12);
$maxSiteWidth: 1440px;

$aspect-ratio: (
  "1:1": 100%,
  "4:3": 75%,
  "3:2": 66.6667%,
  "16:9": 56.25%,
  "2:1": 51.15%,
  "20:9": 45.24%,
  "21:9": 42.85%,
);

$containerOffset: 24px;

// sqrt() is only supported by safari, so we need to use approximate value of sqrt(3)
$sqrt3: 1.73;

$mobileIconSize: 32px;
$desktopIconSize: 48px;
