// Use variables in smaller component parts to keep them standardized
$base-component-margins: (
  "small": var(--space-mini),
  "regular": var(--space-small-regular),
  "large": var(--space-large),
  "big": var(--space-big),
);

// Standard spacing between all big components from Page Layout perspective
.component-standard-spacing {
  margin-top: map-get($base-component-margins, "large");

  @include MQ-up("tablet") {
    margin-top: map-get($base-component-margins, "big");
  }

  &__header {
    margin: 0;
    margin-bottom: var(--space-mini);
    @include MQ-up("tablet") {
      margin-bottom: var(--space-small-regular);
    }
  }
}

// Use mixing for exceptions - where components are considered siblings
@mixin connected-components-spacing {
  margin-top: map-get($base-component-margins, "small");

  @include MQ-up("tablet") {
    margin-top: map-get($base-component-margins, "regular");
  }
}

// Exceptions for standard spacing
#react-breadcrumbs ~ .campaign-page-hero {
  margin-top: 0;
}

.hero-banner,
.hero-banner + section {
  margin-top: map-get($base-component-margins, "large");

  @include MQ-up("tablet") {
    margin-top: map-get($base-component-margins, "big");
  }
}

.banner-container + .banner-container,
.grid-container + .grid-container,
.full-width-banner + [style*="display: none"] + .campaign-banner {
  @include connected-components-spacing();
}

.campaign-hub-hero-section {
  .full-width-banner + .personalized-product-list {
    @include connected-components-spacing();
  }
}

.main-container .hero-banner:first-of-type,
.info-messages + .hero-banner {
  margin-top: 0;
}
