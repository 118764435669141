@import "../theme/button.theme";

html,
body {
  scroll-behavior: smooth;
}

body {
  min-width: 300px;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: var(--font-color-primary);
  font-size: var(--font-size-base);
  font-family: var(--font-family-regular);
  letter-spacing: var(--letter-spacing-base);
  background-color: var(--color-xxl-white);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  position: relative;
  margin-bottom: var(--space-bigger-giant);
  background-color: var(--color-xxl-white);

  &.main-overflow {
    @include max-screen("mobile") {
      z-index: 2000;
    }
  }
}

figure {
  margin: 0;
  padding: 0;
}

main:after {
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  content: ".";
}

.read-more__icon {
  display: inline-block;
  transform: rotate(0deg);
  transition: 0.3s;
  &.read-more__icon--rotated {
    transform: rotate(180deg);
  }
}

a:focus {
  @include outline-for-tabs(2px, var(--color-xxl-black), 2px);
}
a:focus:not(:focus-visible) {
  outline: 0;
}
a:focus-visible {
  @include outline-for-tabs(2px, var(--color-xxl-black), 2px);
}

.keyboard-focus {
  @include keyboardFocus;
}

.clickable {
  cursor: pointer;
}

.site__scroll-hidden {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.hide-scrollbars {
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.js-sticky--bottom {
  position: absolute !important;
  top: auto !important;
  right: 0;
  bottom: 0;
}

.site__full-page-height {
  min-height: 100vh;
}

.site__page-height {
  // Use lowest value for less white space on portrait screens
  min-height: 100vmin;
}

.site__button-checkmark {
  pointer-events: none;

  @include animated-checkmark(
    $size: 24px,
    $thickness: 3px,
    $color: var(--color-xxl-web-black),
    $background-color: var(--color-xxl-green)
  );

  .checkmark {
    display: none;
  }

  &.active .checkmark {
    display: block;
  }

  &--dark {
    .checkmark {
      background-color: var(--color-xxl-black);
      &::after {
        border-top-color: var(--color-xxl-white);
        border-right-color: var(--color-xxl-white);
      }
    }
  }
}

.slick-dots {
  > li {
    display: inline-block;
  }
  > li:only-child {
    display: none;
  }
}

.hidden-test {
  display: none !important;
}

.product__add-to-cart.disabled-button,
.cart-product-info__add-to-cart.disabled-button,
.disabled-button {
  @include button-disabled;
}

.base__loading-circle-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
  background: inherit;
  pointer-events: none;

  &--green {
    background-color: var(--color-xxl-green);
  }
}

.base__loading-circle {
  $size: 20px;
  position: absolute;
  width: $size;
  height: $size;
}

::placeholder {
  color: var(--color-xxl-dark-gray);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--color-xxl-dark-gray);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--color-xxl-dark-gray);
}

.container {
  width: 100%;
  max-width: calc(100% - $containerOffset);
  margin-right: auto;
  margin-left: auto;

  @include MQ-up("small-desktop") {
    max-width: calc(100% - 100px);
  }

  @media only screen and (min-width: map-get($new-breakpoints, "big-desktop")) {
    max-width: $maxContainerWidth;
  }

  @include MQ-up("desktop") {
    &--narrow {
      $offsetTotalWidth: calc(2 * $narrowContainerOffset);
      max-width: $maxContainerWidth - $offsetTotalWidth;
    }
  }

  &--max-container-width {
    max-width: $maxContainerWidth;
  }

  &--max-site-width {
    max-width: $maxSiteWidth;
  }
}

.hidden-on-mobile {
  @include MQ-down("mobile") {
    display: none;
  }
}

.visible-only-on-mobile {
  @include MQ-up("mobile") {
    display: none;
  }
}

.flake {
  position: absolute;
  top: -10px;
  background: white;
  border-radius: 50%;
}
