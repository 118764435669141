@import "~/../../../node_modules/@xxl/theme/dist";

// Mother brand colors
$color-xxl-green: var(--color-xxl-green);
$color-xxl-black: var(--color-xxl-black);
$color-xxl-white: var(--color-xxl-white);
$color-xxl-purple: var(--color-xxl-purple);
$color-xxl-dark-purple: var(--color-xxl-dark-purple);
$color-xxl-orange: var(--color-xxl-orange);
$color-xxl-red: var(--color-xxl-red);
$color-xxl-light-blue: var(--color-xxl-light-blue);

// Gray variants
$color-xxl-web-black: var(--color-xxl-web-black);
$color-xxl-web-gray: var(--color-xxl-web-gray);
$color-xxl-gray: var(--color-xxl-gray);
$color-xxl-light-gray: var(--color-xxl-light-gray);
$color-xxl-medium-gray: var(--color-xxl-medium-gray);
$color-xxl-dark-gray: var(--color-xxl-dark-gray);
