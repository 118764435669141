$productHoverOffset: 3px;
$margin: 21px;

.product-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  box-sizing: border-box;
  width: 100%;
  min-height: 466px;
  margin-top: 0;
  margin-bottom: var(--space-small-regular);
  padding: 0;
  column-gap: var(--space-mini);
  list-style-type: none;
  row-gap: var(--space-mini);

  @include MQ-up("tablet") {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    margin-bottom: var(--space-regular);
  }

  @include MQ-up("laptop") {
    min-height: 556px;
    margin-bottom: var(--space-large);
    padding-left: 3px; // It's needed for aligning spaces with two column banners above
    column-gap: var(--space-small-regular);
    row-gap: var(--space-small-regular);
  }

  &--in-modal {
    min-height: auto;
  }

  &.product-list--multiline {
    grid-template-columns: 1fr;

    @include MQ-up("laptop") {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  &.product-list--4-col-max {
    @include MQ-up("laptop") {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      row-gap: var(--space-small-regular);
    }
  }

  .slick-list {
    // Second value should be the negative of the corresponding value for .slick-slide
    margin: 0 calc(-1 * var(--space-micro));

    .slick-slide {
      margin: 0 var(--space-micro);
    }
  }

  .slick-arrow {
    top: 50%;
    transform: translateY(-50%);

    & use {
      color: $color-xxl-web-gray;
    }
  }
}

.product-list__product--loading {
  opacity: 0.3;
}

.product-list__discount {
  position: absolute;
  bottom: 10px;
  left: 10px;
  width: 80px;
  padding: 7px 0;
  color: var(--font-color-primary-invert);
  font-size: 1.1rem;
  font-family: var(--font-family-medium);
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  background-color: var(--color-xxl-black);

  @include max-screen("mobile") {
    bottom: 4px;
    left: 4px;
    width: 55px;
    padding: 4px 7px;
    font-size: 0.8rem;
    line-height: 17px;
    text-align: center;
  }

  span {
    color: var(--font-color-primary-invert);
  }
}
