$spaces: (
  "hair": 1,
  "line": 2,
  "micro": 4,
  "micro-mini": 6,
  "mini": 8,
  "mini-regular": 10,
  "small-regular": 12,
  "regular": 16,
  "small": 18,
  "small-large": 20,
  "large": 24,
  "big": 36,
  "bigger-big": 40,
  "small-huge": 48,
  "huge": 56,
  "small-giant": 72,
  "giant": 96,
  "bigger-giant": 120,
);
:root {
  @each $space, $value in $spaces {
    --space-#{$space}: #{$value}px;
  }

  // It should be overwritten by breakpoint-manager function. This is only fallback value.
  --xxl-vh: 100vh;
}
