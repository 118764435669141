/** Sharing some global css with the legacy app during Next.js rewrite. */
@import "../../../application/src/main/resources/frontend/styles/helpers/_mixins",
  "../../../application/src/main/resources/frontend/styles/helpers/_colors",
  "../../../application/src/main/resources/frontend/styles/helpers/_spaces",
  "../../../application/src/main/resources/frontend/styles/helpers/_theme-helpers",
  "../../../application/src/main/resources/frontend/styles/helpers/_base",
  "../../../application/src/main/resources/frontend/styles/helpers/_global",
  "../../../application/src/main/resources/frontend/styles/helpers/_button",
  "../../../application/src/main/resources/frontend/styles/components/_category-list",
  "../../../application/src/main/resources/frontend/styles/components/_category-list-sort",
  "../../../application/src/main/resources/frontend/styles/components/_category-page-hero",
  "../../../application/src/main/resources/frontend/styles/components/_category-selector",
  "../../../application/src/main/resources/frontend/styles/components/_category-promoted-service",
  "../../../application/src/main/resources/frontend/styles/components/_exit-denmark",
  "../../../application/src/main/resources/frontend/styles/components/_guide-carousel",
  "../../../application/src/main/resources/frontend/styles/components/_guide-components",
  "../../../application/src/main/resources/frontend/styles/components/_guide-teaser",
  "../../../application/src/main/resources/frontend/styles/components/mini-banner",
  "../../../application/src/main/resources/frontend/styles/components/_long_tail_title",
  "../../../application/src/main/resources/frontend/styles/components/_product",
  "../../../application/src/main/resources/frontend/styles/components/_product-list",
  "../../../application/src/main/resources/frontend/styles/components/_product-card",
  "../../../application/src/main/resources/frontend/styles/components/search-list",
  "../../../application/src/main/resources/frontend/styles/components/_ribbon",
  "../../../application/src/main/resources/frontend/styles/components/_top-usp",
  "../../../application/src/main/resources/frontend/styles/lib/slick",
  "../../../application/src/main/resources/frontend/styles/lib/slick-theme",
  "../../../application/src/main/resources/frontend/styles/components/banner",
  "../../../application/src/main/resources/frontend/styles/components/hero-component",
  "../../../application/src/main/resources/frontend/styles/components/_info-messages",
  "../../../application/src/main/resources/frontend/styles/components/select-box";

@font-face {
  font-family: "XXLSans";
  src: url("../public/fonts/XXLSans-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "XXLSans medium";
  src: url("../public/fonts/XXLSans-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "XXLSans bold";
  src: url("../public/fonts/XXLSans-Bold.woff2") format("woff2");
  font-display: swap;
}

:root {
  --font-color-primary: var(--color-xxl-black);
  --font-color-primary-invert: var(--color-xxl-white);
  --font-color-green: var(--color-xxl-green);
  --font-color-light-gray: var(--color-xxl-light-gray);
  --font-color-mid-gray: var(--color-xxl-gray);
  --font-color-dark-gray: var(--color-xxl-web-gray);
  --font-size-base: 16px;
  --font-family-regular: "XXLSans", sans-serif;
  --font-family-medium: "XXLSans medium", sans-serif;
  --font-family-bold: "XXLSans bold", sans-serif;
  --letter-spacing-base: 0;
}
