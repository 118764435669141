html.exit-denmark #js-react-header,
html.exit-denmark header:first-of-type {
  height: 52px;
}

@media (min-width: 1024px) {
  html.exit-denmark #js-react-header,
  html.exit-denmark header:first-of-type {
    height: 76px;
  }
}

html.exit-denmark header:first-of-type #desktop-mega-menu,
html.exit-denmark header:first-of-type .search-suggestions-wrapper,
html.exit-denmark header:first-of-type #xxl-react-login-link,
html.exit-denmark header:first-of-type .mini-cart-button,
html.exit-denmark header:first-of-type nav {
  display: none;
}
