$date-styling: (
  "mobile": (
    "font-size": 12,
    "padding": 5px,
    "font-family": regular,
    "line-height": 1,
    "white-space": nowrap,
    "box-sizing": border-box,
    "text-overflow": ellipsis,
    "overflow": hidden,
    "border": 1px solid $color-xxl-light-gray,
  ),
);
