@import "../helpers/components-spacing";

.category-list-sort {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: map-get($base-component-margins, "big");
  overflow: hidden;

  @include max-screen("mobile") {
    flex-direction: column;
    align-items: center;
    order: 2;
    margin-bottom: map-get($base-component-margins, "medium");
  }
}

.category-list-sort--remove-margin {
  margin-left: 0;
}

.category-list-sort__sort-by {
  @include Typography(
    (
      "mobile": (
        "font-family": bold,
        "font-size": 20,
      ),
      "tablet": (
        "font-size": 15,
      ),
    )
  );
  display: inline-block;
  margin: 0;
  margin-right: 14px;
  color: var(--color-xxl-web-black);

  @include max-screen("mobile") {
    display: block;
    padding: 0;
    text-align: left;
  }
}

.category-list-sort__header-wrapper {
  display: flex;

  button {
    display: none;
  }

  @include max-screen("mobile") {
    align-items: center;
    padding: 8px 8px 0 0;
    button {
      display: block;
    }
  }
}

.category-list-sort__total-items {
  display: flex;
  align-items: center;
  margin: 0 5px 0 0;
  margin-bottom: var(--space-large);
  color: $color-xxl-web-gray;
  font-size: px-to-rem(15);
  line-height: 1.2;

  &--no-top-margin {
    margin-top: 0;
  }

  @include max-screen("mobile") {
    display: block;
    align-self: flex-start;
    margin-top: 0;
    margin-bottom: var(--space-small-regular);
  }
}

.category-list-sort__mobile-close-dialog {
  padding: 14px 8px;
  font-size: 0;
  background-color: transparent;
  border: none;

  svg {
    width: 20px;
    height: 20px;
  }

  @include MQ-up("tablet") {
    display: none;
  }
}

.category-list-sort__items-dialog-wrap {
  @include max-screen("mobile") {
    position: relative;
    min-height: 100vh;
    padding-bottom: 59px;
  }
}

.category-list-sort__items-wrap {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  text-align: right;

  @include max-screen("mobile") {
    position: fixed;
    top: 0;
    left: 100%;
    z-index: 1050;
    display: block;
    width: 100vw;
    height: 100%;
    overflow: auto;
    background-color: var(--color-xxl-white);
    transition: left 200ms;

    &:last-child {
      margin-bottom: 60px;
    }
  }
}

.category-list-sort__items-wrap--active {
  @include max-screen("mobile") {
    left: 0;
  }
}

.category-list-sort__item {
  display: inline-block;
  margin-right: 14px;
  font-size: 1rem;
  text-decoration: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  @include max-screen("mobile") {
    display: block;
    margin: 0;
    padding: 14px 10px;
    text-align: left;

    &:last-child {
      margin-bottom: 60px;
    }
  }
}

.category-list-sort__item--selected {
  position: relative;
  font-family: var(--font-family-bold);

  @include max-screen("mobile") {
    padding-bottom: 14px;
  }
}

.category-list-sort__mobile-buttons-wrap {
  display: none;

  @include max-screen("mobile") {
    display: flex;
    gap: var(--space-mini);
    justify-content: space-between;
    width: 100%;
    margin-top: var(--space-mini);
  }
}
