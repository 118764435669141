$hero-label: (
  "mobile": (
    "font-family": regular,
    "font-size": 12,
    "letter-spacing": -0.15,
    "line-height": 1.3,
    "text-transform": none,
    "opacity": 0.8,
  ),
  "tablet": (
    "font-size": 15,
    "line-height": 1.2,
  ),
);

$hero-heading: (
  "mobile": (
    "font-size": 34,
    "letter-spacing": -0.15,
    "line-height": 1,
    "text-transform": none,
    "word-break": break-word,
  ),
  "tablet": (
    "font-size": 34,
  ),
  "desktop": (
    "font-size": 60,
    "letter-spacing": -0.3,
  ),
);

$hero-sub-heading: (
  "mobile": (
    "font-family": medium,
    "font-size": 15,
    "line-height": 1,
  ),
  "tablet": (
    "font-size": 20px,
    "line-height": 1,
  ),
);

$hero-bullet: (
  "mobile": (
    "font-family": var(--font-family-bold),
    "font-size": 20,
    "line-height": 1.6,
    "letter-spacing": -0.1,
  ),
);
