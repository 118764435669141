@import "../theme/banner.theme";
@import "../theme/hero-banner.theme";

.banner {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  text-decoration: none;

  &-container {
    position: relative;
    z-index: 1;
  }

  &--link {
    a {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      justify-content: flex-end;
    }

    &:hover {
      .banner__button {
        color: $color-xxl-web-black;
        background-color: $color-xxl-green;
      }
      .banner__transparent-button-text::after {
        right: 1px;
      }
    }
  }

  &.campaign-banner__item {
    &:focus .banner__button {
      outline: none;
    }
  }

  &--text-top &__text-wrapper {
    justify-content: flex-start;
  }

  &--text-bottom &__text-wrapper {
    justify-content: flex-end;
  }

  &__responsive-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;

    &--image-overlay {
      @include gradientImageOverlay(1);
    }

    .campaign-banner & {
      min-height: 168px;

      @include MQ-up("tablet") {
        min-height: 200px;
      }

      @include MQ-up("laptop") {
        min-height: 280px;
      }
    }

    .full-width-banner & {
      min-height: 220px;

      @include MQ-up("tablet") {
        min-height: 400px;
      }

      @include MQ-up("laptop") {
        min-height: 540px;
      }
    }

    .two-column-grid & {
      min-height: 350px;

      @include MQ-up("tablet") {
        min-height: 400px;
      }

      @include MQ-up("laptop") {
        min-height: $medium-banner-desktop-height;
      }
    }

    > *:not(.banner__image) {
      z-index: 2;
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-wrapper {
    flex-grow: 1;
    padding: var(--space-mini) $banner-right-padding-sm var(--space-mini)
      var(--space-small-regular);

    @include MQ-up("laptop") {
      padding: var(--space-large) $banner-right-padding var(--space-large)
        var(--space-large);
    }

    &--grid {
      display: flex;
      flex-direction: column;
      max-width: 100%;
      padding: var(--space-regular) $banner-right-padding-sm
        var(--space-regular) var(--space-small-regular);

      @include MQ-up("laptop") {
        padding: var(--space-large) $banner-right-padding var(--space-large)
          var(--space-large);
      }
    }

    .campaign-hub-hero-section & {
      width: 100%;
      max-width: 100%;

      @include MQ-up("tablet") {
        width: 70%;
      }

      @include MQ-up("laptop") {
        width: 60%;
      }
    }
  }

  &__header {
    display: flex;
    width: 100%;
    &__text {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }

    &__ribbon-wrapper {
      .ribbon {
        justify-content: flex-end;
      }
    }
  }

  &__headline {
    @include Typography($banner-text);
    margin: 0 0 var(--space-micro);
    color: $color-xxl-white;

    &.banner--inverted {
      color: $color-xxl-black;
    }
  }

  &__text {
    @include Typography($banner-text);
    display: inline-block;
    margin-bottom: var(--space-micro);
    color: $color-xxl-white;

    @include MQ-up("tablet") {
      margin-bottom: var(--space-mini);
    }

    &.banner--inverted {
      color: $color-xxl-black;
    }

    .campaign-hub-hero-section & {
      @include Typography($hero-heading);
    }
  }

  &__description {
    @include Typography($banner-description);
    margin: 0;
    color: $color-xxl-white;

    &.banner--inverted {
      color: $color-xxl-black;
    }
  }

  &__bottom-row,
  &__button-wrap {
    padding: 0 var(--space-small-regular) var(--space-small-regular);

    @include MQ-up("laptop") {
      padding: 0 var(--space-large) var(--space-large);
    }
  }

  &__button {
    .campaign-banner & {
      pointer-events: none;
    }
  }

  &__type {
    @include Typography($banner-type);
    margin-bottom: 4px;
    color: $color-xxl-white;

    &.banner--inverted {
      color: $color-xxl-black;
    }
  }

  &__transparent-button {
    position: relative;
    margin: 0;
    padding: 0;
    color: inherit;
    background-color: transparent;
    border: none;
    outline: none;
    pointer-events: none;
  }

  &__transparent-button-text {
    @include Typography($text-banner-button);
    position: relative;
    display: inline-block;
    padding-right: var(--space-regular);
    color: currentColor;

    // Right chevron
    &::after {
      position: absolute;
      top: 0;
      right: 4px;
      bottom: 0;
      display: block;
      width: 7px;
      height: 7px;
      margin: auto;
      border-top: 1px solid currentColor;
      border-right: 1px solid currentColor;
      transform: rotate(45deg);
      transition: right 0.2s ease;
      content: "";
    }
  }
}

// Setting heights to avoid CLS.
.full-grid-banner {
  $largeScreenHeight: 400px;

  height: 300px;
  margin-top: 12px;

  &--multi {
    height: 316px;

    @include MQ-up("laptop") {
      height: $largeScreenHeight;
    }
  }

  @include MQ-up("laptop") {
    height: $largeScreenHeight;
    margin-top: 24px;
  }
}
