.search-list__header {
  h1 {
    display: inline-block;
    margin: 21px 0 28px;
    font-weight: 500;
    font-size: 2rem;

    @include max-screen("mobile") {
      margin: 14px 0;
      font-size: 1.1rem;
      line-height: 32px;
    }
  }
}

.search-list {
  overflow: hidden;

  @include max-screen("mobile") {
    display: flex;
    flex-direction: column;
  }

  .category-list-sort {
    order: 0;
    margin-left: 21px;
    @include max-screen("mobile") {
      margin-left: 0;
    }
  }
}

.search-list__no-result-wrapper {
  margin: var(--space-bigger-big) 0 var(--space-big);
  text-align: center;

  @include MQ-up("tablet") {
    margin: var(--space-bigger-giant) 0 var(--space-bigger-giant);
  }
}

.search-list__no-result-icon {
  width: 180px;
  height: 118px;
  margin-bottom: var(--space-small-large);

  @include MQ-up("tablet") {
    width: 120px;
    height: 74px;
  }
}

.search-list__no-result-headline {
  margin: 0 0 var(--space-small-regular);
  font-size: px-to-rem(20);
  font-family: var(--font-family-medium);

  @include MQ-up("tablet") {
    margin: 0 0 var(--space-small-large);
  }
}

.search-list__no-result-message {
  margin: 0;
  padding: 0 20px 45px;
  font-size: px-to-rem(15);
  text-align: center;

  @include max-screen("mobile") {
    font-size: 1rem;
  }
}

.search-list__related-products {
  padding-top: 45px;

  h2 {
    display: none;
  }

  & > .product-list {
    @include MQ-up("laptop") {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}
